import { isEmpty } from 'ramda'

import Link from '@atoms/UILink/Link'
import { VerifiedIcon } from '@atoms/Icon/Icon'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import Currency from '@atoms/UICurrency/Currency'
import { Box, Checkbox, Flex, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { supportLinks } from '@utils/supportLinks'

type Props = {
  sale: SaleDetailsType
  warrantyId: number | null
  onChangeWarranty: Function
}

const styles = {
  container: {
    mb: 5
  },
  header: {
    mb: 4,
    svg: {
      mr: 2,
      position: 'relative',
      top: '2px'
    },
    strong: {
      mr: 12
    }
  },
  learnMore: {
    fontSize: 1,
    textDecoration: 'underline'
  },
  checkboxWrapper: {
    cursor: 'pointer',
    alignItems: 'center',
    '&:not(:last-child)': {
      mb: 5
    }
  },
  checkbox: {
    width: '100%',
    alignItems: 'center',
    '&:not(:last-child)': {
      mb: 5
    },
    span: {
      fontSize: ['sm', 'md']
    }
  } as UIStyles
}

const WarrantyList = ({ sale, onChangeWarranty, warrantyId }: Props) => {
  if (isEmpty(sale.saleWarranties)) return null

  return (
    <Box sx={styles.container}>
      <Flex sx={styles.header}>
        <VerifiedIcon />
        <Text as="strong">Protect Your Product</Text>
        <Link
          sx={styles.learnMore}
          to={supportLinks.warranties()}
          aria-label="Learn more about the product warranties"
        >
          Learn More
        </Link>
      </Flex>

      {sale.saleWarranties.map((item) => (
        <Checkbox
          sx={styles.checkbox}
          data-testid="warrantyItem"
          key={item.databaseId}
          id={item.databaseId as unknown as string}
          name="warranties"
          isChecked={warrantyId === item.databaseId}
          onChange={() => onChangeWarranty(item.databaseId as number)}
          size="lg"
          variant="black"
        >
          <Text>
            {item.name}{' '}
            <Text as="strong">
              <Currency>{item.priceInCents}</Currency>
            </Text>
          </Text>
        </Checkbox>
      ))}
    </Box>
  )
}

export default WarrantyList
