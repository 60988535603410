import parse from 'html-react-parser'

import { ProductType, SaleDetailsType } from '@concepts/Sales/types/sale'
import CollapsibleCard from '@molecules/CollapsibleCard/CollapsibleCard'
import ListWithHorizontalTitle from '@molecules/ListWithHorizontalTitle/ListWithHorizontalTitle'
import SaleFullDescription from '@molecules/SaleFullDescription/SaleFullDescription'
import { CourseOverview } from '@organisms/CourseOverview/CourseOverview'
import ShowMore from '@molecules/ShowMore/ShowMore'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type CollapsibleCardListProps = {
  id?: string
  products: ProductType[]
  title: string
  terms: string
  countries: string
  sx?: UIStyles
}

const styles = {
  title: {
    fontWeight: 'bold',
    fontSize: ['26px', '26px', '26px', '29px'],
    mb: 7
  },
  card: {
    '&:not(:first-of-type)': {
      mt: 5
    }
  },
  showMoreWrapper: {
    px: [4, 4, '42px', '42px', '42px'],
    pb: [6, 10]
  }
}

const CollapsibleCardList = ({
  products,
  title,
  terms,
  countries,
  ...props
}: CollapsibleCardListProps) => {
  const saleConstructor = (prod: ProductType) => {
    return {
      title: prod.name,
      tabs: [
        [
          { title: 'Description', body: prod.description },
          { title: 'Specs', body: prod.specs },
          { title: 'Instructor', body: prod.instructor }
        ]
      ],
      terms,
      shippingMessage: countries,
      firstProduct: {
        vendorName: prod.vendorName,
        instructor: prod.instructor
      },
      products
    }
  }

  return (
    <>
      <Box data-testid="collapsibleCardList" {...props}>
        <Text as="h2" sx={styles.title}>
          {title}
        </Text>

        {products.map((prod) => {
          const isMissingProductDetails = !prod.description

          return (
            <CollapsibleCard
              sx={styles.card}
              key={prod.id}
              id={prod.id}
              title={prod.name}
              price={prod.retailPriceInCents}
              author={prod.vendorName as string}
              numberOfLessons={prod.course?.numberOfLessons as number}
              duration={prod.course?.duration as number}
              license={prod.course?.accessTimeFrame as string}
              image={prod.imageCollection?.productShotWide?.url as string}
              isMissingDetails={isMissingProductDetails}
            >
              {isMissingProductDetails ? null : (
                <ShowMore
                  scrollOnClose
                  maxHeight={700}
                  sx={styles.showMoreWrapper}
                >
                  <CourseOverview product={prod} />
                  <SaleFullDescription
                    sale={saleConstructor(prod) as unknown as SaleDetailsType}
                    showTerms={false}
                    showMore={false}
                  />
                </ShowMore>
              )}
            </CollapsibleCard>
          )
        })}
      </Box>

      <ListWithHorizontalTitle title="Terms">
        {parse(terms)}
      </ListWithHorizontalTitle>
    </>
  )
}

export default CollapsibleCardList
