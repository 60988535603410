import { Flex } from 'rebass'

import Heading from '@atoms/Heading/Heading'
import OptionRadio, {
  Props as LineRatioProps,
  RadioType
} from '@molecules/OptionRadio/OptionRadio'

const styles = {
  wrapper: {
    flexDirection: 'column',
    maxWidth: 410,
    mb: '40px'
  },
  line: {
    position: 'relative',
    zIndex: 0,
    mt: '-1px',
    border: `1px solid`,
    borderColor: `grays.2`,
    px: [2, 3],
    py: [1, 3],
    transition: '250ms ease',
    '&:hover': {
      backgroundColor: 'rgba(245,245,245,0.45)'
    },
    '&:first-of-type': {
      mt: 0,
      borderTopLeftRadius: 'default',
      borderTopRightRadius: 'default'
    },
    '&:last-of-type': {
      borderBottomLeftRadius: 'default',
      borderBottomRightRadius: 'default'
    }
  },
  active: {
    zIndex: 1,
    borderColor: 'blacks.0'
  }
}

type Props = {
  title: string
  selected?: string
  items: Array<LineRatioProps['optionData']>
  radioType: RadioType
}

const MultilineRadio: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  selected,
  items,
  radioType,
  ...props
}) => {
  return (
    <Flex sx={{ ...styles.wrapper }} {...props} data-testid="multilineRadio">
      <Heading level={4} mb={4}>
        {title}
      </Heading>

      {items.map((line, key) => {
        const isChecked = selected === line.slug
        const border = isChecked ? styles.active : {}

        return (
          <OptionRadio
            key={key}
            name={line.name}
            optionData={line}
            radioType={radioType}
            soldOut={line.soldOut}
            sx={{ ...styles.line, ...border }}
            checked={isChecked}
          />
        )
      })}
    </Flex>
  )
}

export default MultilineRadio
