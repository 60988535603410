import { Box } from 'rebass'

import { SaleDetailsProps } from '@concepts/Sales/types/sale'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'
import BundleSaleHeader from '@molecules/BundleSaleHeader/BundleSaleHeader'
import CollapsibleCardList from '@molecules/CollapsibleCardList/CollapsibleCardList'
import Container from '@atoms/Container/Container'
import SingleLineSeparator from '@atoms/Separator/SingleLineSeparator'

const Bundle: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  const styles = {
    container: {
      pt: [6, 6, 9, 10]
    }
  }

  return (
    <Box>
      <AddToCartProvider>
        <BundleSaleHeader sale={sale} />
      </AddToCartProvider>

      <Container
        sx={styles.container}
        withDefaultPadding
        removeDeskPaddingBottom
      >
        <CollapsibleCardList
          products={sale.products}
          terms={sale.terms}
          countries={sale.shippingMessage}
          title="What's Included"
        />
      </Container>

      <SingleLineSeparator />
    </Box>
  )
}

export default Bundle
