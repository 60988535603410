import parse from 'html-react-parser'
import { head } from 'ramda'

import Tagline from '@molecules/Tagline/Tagline'
import Container from '@atoms/UIContainer/Container'
import ShowMore from '@molecules/ShowMore/ShowMore'
import Instructor from '@molecules/Instructor/Instructor'
import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import ShippingList from '@molecules/ShippingList/ShippingList'
import ListWithHorizontalTitle from '@molecules/ListWithHorizontalTitle/ListWithHorizontalTitle'

import { Product, ProductTab } from 'src/generated/graphql'
import { Box } from '@lib/UIComponents'

const styles = {
  listWithHorizontalTitle: (showMore: boolean) => ({
    borderBottom: '1px solid',
    borderColor: 'grays.100',
    '&:last-child': {
      borderBottomWidth: showMore ? [0, 0, '1px'] : 0
    },
    img: {
      maxWidth: ['none', 'none', 'none', '80%']
    }
  })
}

type SaleFullDescriptionProps = {
  sale: SaleDetailsType
  showTerms?: boolean
  showMore?: boolean
  showMoreMobileOnly?: boolean
}

const getTab = (tabs: ProductTab[][]) => {
  const tab = head(tabs) || []

  return tab.filter((t) => t.title !== 'Instructor' && Boolean(t.body))
}

const SaleFullDescription = ({
  sale,
  showTerms = true,
  showMore = true,
  showMoreMobileOnly = true
}: SaleFullDescriptionProps) => {
  const { tabs, terms, products } = sale
  const countries = sale.shippingMessage
  const tagline = sale.title

  const isCourse = products.some((product) => product.course)

  const firstProduct = sale.firstProduct as Product

  const tab = getTab(tabs)

  return (
    <>
      {tagline && (
        <Tagline title={tagline} isCourse={isCourse} id="description" />
      )}

      {isCourse && sale.type !== SaleTypeEnum.Recurring && (
        <Box id="instructor">
          <Instructor
            author={firstProduct.vendorName as string}
            description={parse(firstProduct.instructor || '')}
          />
        </Box>
      )}

      <Container
        id="importantDetails"
        withDefaultPadding
        removeDeskPaddingBottom
      >
        {showMore ? (
          <ShowMore mobileOnly={showMoreMobileOnly}>
            {tab.map(({ title, body }) => (
              <ListWithHorizontalTitle
                key={title}
                title={title}
                sx={styles.listWithHorizontalTitle(showMore)}
              >
                {title === 'Shipping' ? (
                  <ShippingList countries={countries}>
                    {parse(body) as React.ReactElement}
                  </ShippingList>
                ) : (
                  parse(body)
                )}
              </ListWithHorizontalTitle>
            ))}
          </ShowMore>
        ) : (
          <>
            {tab.map(({ title, body }) => (
              <ListWithHorizontalTitle
                key={title}
                title={title}
                sx={styles.listWithHorizontalTitle(showMore)}
              >
                {title === 'Shipping' ? (
                  <ShippingList countries={countries}>
                    {parse(body) as React.ReactElement}
                  </ShippingList>
                ) : (
                  parse(body)
                )}
              </ListWithHorizontalTitle>
            ))}
          </>
        )}

        {showTerms && (
          <>
            <ListWithHorizontalTitle title="Terms">
              {parse(terms)}
            </ListWithHorizontalTitle>
          </>
        )}
      </Container>
    </>
  )
}

export default SaleFullDescription
