import { Box } from 'rebass'

import Brand from '@atoms/Brand/Brand'
import Container from '@atoms/Container/Container'
import FullLoader from '@atoms/Loader/FullLoader'
import Breadcrumb from '@atoms/Breadcrumb/Breadcrumb'
import Text from '@atoms/Text/Text'

import SaleGallery from '@molecules/SaleGallery/SaleGallery'
import SaleSummary from '@molecules/SaleSummary/SaleSummary'
import WarrantyList from '@molecules/WarrantyList/WarrantyList'
import SaleVariantPicker from '@molecules/SaleVariantPicker/SaleVariantPicker'
import SalePurchaseButton from '@molecules/SalePurchaseButton/SalePurchaseButton'
import useAddSaleToCart from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'

import { SaleDetailsProps } from '@concepts/Sales/types/sale'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'

const styles = {
  container: {
    pb: [2, 7, 8, 10, '80px'],
    pt: [4, 6, 8]
  },
  grid: {
    display: 'grid',
    gridColumnGap: [0, 0, 0, 10, '80px'],
    gridTemplateColumns: ['1fr', '1fr', '1fr', '1.2fr .8fr', '1.2fr .8fr']
  },
  galleryWrapper: {
    gridRowStart: 1,
    gridRowEnd: 7
  },
  brand: {
    mt: -2
  }
}

const Solo: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  const { warrantyId, onChangeWarranty, paypalLoading } = useAddSaleToCart({
    sale
  })
  return (
    <>
      <Text
        hidden
        data-testid="physicalOrDigitalSale"
        value={sale.ships ? 'PHYSICAL' : 'DIGITAL'}
      />
      <FullLoader show={paypalLoading} message="Processing payment..." />

      <Container withDefaultPadding sx={styles.container}>
        <Breadcrumb breadcrumb={sale.breadcrumb} sx={{ ml: [0, 3] }} />
        <AddToCartProvider>
          <Box sx={styles.grid}>
            <Box sx={styles.galleryWrapper}>
              <SaleGallery mediaList={sale.medias} saleName={sale.name} />
            </Box>

            <SaleSummary sale={sale} />

            <Brand brand={sale.brand} sx={styles.brand} />
            <SaleVariantPicker saleGroup={sale.group} />

            <SalePurchaseButton sale={sale} warrantyId={warrantyId as number} />

            <WarrantyList
              sale={sale}
              onChangeWarranty={onChangeWarranty}
              warrantyId={warrantyId as number}
            />
          </Box>
        </AddToCartProvider>
      </Container>
    </>
  )
}

export default Solo
