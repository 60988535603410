import HowItWorks from '@molecules/HowItWorks/HowItWorks'
import SaleHeader from '@molecules/SaleHeader/SaleHeader'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'
import PriceOffer from '@molecules/PriceOffer/PriceOffer'
import { Box } from '@lib/UIComponents'

type Props = {
  sale: SaleDetailsType
  placedOnImageBackground?: boolean
}

const SalePageHeader = ({ sale, placedOnImageBackground = false }: Props) => {
  return (
    <Box
      sx={{
        color: placedOnImageBackground
          ? ['currentcolor', 'currentcolor', 'currentcolor', 'white']
          : 'currentcolor'
      }}
    >
      <AddToCartProvider>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto',
            gridTemplateRows: '0fr 1fr',
            gap: '0 64px',
            gridTemplateAreas: [
              "'titleBlock' 'howItWorks' 'priceOffer'",
              "'titleBlock' 'howItWorks' 'priceOffer'",
              "'titleBlock titleBlock' 'howItWorks priceOffer'",
              "'titleBlock titleBlock priceOffer' 'howItWorks howItWorks priceOffer'"
            ]
          }}
        >
          <SaleHeader
            sale={sale}
            sx={{
              display: ['none', 'none', 'none', 'block'],
              maxWidth: ['100%', '100%', '100%', '80%'],
              gridArea: 'titleBlock'
            }}
            placedOnImageBackground={placedOnImageBackground}
          />
          <HowItWorks
            sx={{
              gridArea: 'howItWorks',
              mb: [7, 7, 0]
            }}
            placedOnImageBackground={placedOnImageBackground}
          />
          <PriceOffer
            sale={sale}
            sx={{
              gridArea: 'priceOffer'
            }}
            placedOnImageBackground={placedOnImageBackground}
          />
        </Box>
      </AddToCartProvider>
    </Box>
  )
}

export default SalePageHeader
