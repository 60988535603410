import Collapse from '@kunukn/react-collapse'
import { animated, useSpring } from 'react-spring'
import { isEmpty, mergeDeepRight } from 'ramda'
import { DOMNode, domToReact, Element } from 'html-react-parser'

import { getData, separateTitleFromTime } from './utils'
import { ChevronDownIcon } from '@atoms/Icon/Icon'
import { CourseOutlineStyle } from '@molecules/CourseOutline/CourseOutline'
import { Box, Text } from '@lib/UIComponents'

export type ExpandableListItemProps = {
  activeItem?: number | null
  id: number | null
  onClick?: Function
  title: DOMNode[]
  content: DOMNode[]
  variation?: CourseOutlineStyle
}

const getStyles = (variation: CourseOutlineStyle, hasContent: boolean) => {
  const bundleStyles = {
    wrapper: {
      listStyleType: 'none',
      px: hasContent ? 0 : [0, 0, 0, 6, 6]
    },
    top: {
      alignItems: 'center',
      display: 'grid',
      gridGap: 2,
      gridTemplateColumns: '1fr 1.5rem',
      borderColor: 'grays.100',
      borderStyle: 'solid',
      borderWidth: 0,
      px: hasContent ? [0, 0, 0, 6, 6] : 0,
      cursor: hasContent ? 'pointer' : 'auto',
      borderBottomWidth: hasContent ? 'none' : '1px'
    },
    hiddenRow: {
      py: 2,
      px: [4, 4, 4, 6, 6],
      backgroundColor: 'grays.40'
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      py: 3,
      '& > span': {
        color: 'blacks.500',
        fontSize: 'sm',
        '&:first-of-type': {
          flex: 1,
          mr: 6
        }
      }
    },
    title: {
      py: 5,
      fontSize: ['sm', 'sm', 'sm', 'md'],
      color: 'grays.650',
      p: {
        padding: 0,
        m: 0
      },
      div: {
        mb: '-8px !important',
        mt: '10px !important',
        mx: '0 !important',
        a: {
          textDecoration: 'none'
        }
      }
    }
  }

  const courseStyles = {
    title: {
      color: 'blacks.500'
    },
    hiddenRow: {
      px: [4, 4, 4, 6, 6]
    },
    top: {
      px: hasContent ? [4, 4, 4, 6, 6] : [4, 4, 4, 0, 0]
    }
  }

  switch (variation) {
    case CourseOutlineStyle.Course:
      return mergeDeepRight(bundleStyles, courseStyles)

    default:
      return bundleStyles
  }
}

const ExpandableListItem = ({
  activeItem = null,
  id,
  onClick,
  content = [],
  title,
  variation = CourseOutlineStyle.Bundle
}: ExpandableListItemProps) => {
  const active = activeItem === id
  const hasContent = !isEmpty(content)
  const styles = getStyles(variation, hasContent)
  const contentText = getData(content as unknown as Element[])

  const handleClick = (): void => {
    if (onClick) {
      if (hasContent === true) {
        onClick(activeItem === id ? null : id)
      }
    }
  }

  const { transform } = useSpring({
    transform: active ? 'rotate(180deg)' : 'rotate(0deg)'
  })

  return (
    <Box as="li" sx={styles.wrapper}>
      <Box sx={styles.top} onClick={handleClick}>
        <Text sx={styles.title}>{domToReact(title)}</Text>
        {hasContent && (
          <Box sx={{ textAlign: 'right' }}>
            <animated.span
              style={{
                transform,
                lineHeight: 0,
                transformOrigin: 'center',
                display: 'inline-block'
              }}
            >
              <ChevronDownIcon />
            </animated.span>
          </Box>
        )}
      </Box>

      {hasContent && (
        <Collapse
          isOpen={active}
          transition="height 350ms ease"
          overflowOnExpanded
        >
          <Box sx={styles.hiddenRow}>
            {contentText.map((lesson, key) => {
              const { time, title } = separateTitleFromTime(lesson)

              return title ? (
                <Box key={key} sx={styles.row}>
                  <Text>{title}</Text>
                  {time && <Text>{time}</Text>}
                </Box>
              ) : null
            })}
          </Box>
        </Collapse>
      )}
    </Box>
  )
}

export default ExpandableListItem
