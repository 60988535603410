import { mergeDeepRight } from 'ramda'

import BundleInfo from '@atoms/BundleInfo/BundleInfo'
import Container from '@atoms/UIContainer/Container'
import Heading from '@atoms/UIHeading/Heading'

import { SaleDetailsType } from '@concepts/Sales/types/sale'
import WishlistButton from '@concepts/Wishlist/views/WishlistButton'

import { Box, Flex } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

import Ratings from '@molecules/Ratings/Ratings'
import SalePriceDropMessage from '@molecules/SalePriceDrop/Message'
import useScrollToElement from '@molecules/SaleTabs/hooks/useScrollToElement'

type Props = {
  sale: SaleDetailsType
  placedOnImageBackground?: boolean
  sx?: UIStyles
}

const SaleHeader = ({
  sale,
  placedOnImageBackground = false,
  sx = {}
}: Props) => {
  const { scrollToElementId } = useScrollToElement()

  const {
    id,
    name,
    expiresAt,
    soldOut,
    saleReviews,
    numberOfCourses,
    specialSaleBannerBgUrl
  } = sale

  const { averageScore, totalCount } = saleReviews

  return (
    <Flex
      sx={mergeDeepRight(
        {
          backgroundImage: placedOnImageBackground
            ? [
                `url(${specialSaleBannerBgUrl})`,
                `url(${specialSaleBannerBgUrl})`,
                `url(${specialSaleBannerBgUrl})`,
                'none'
              ]
            : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          pb: [0, 0, 0, 8],
          mb: 6,
          pl: [0, 0, 0, 12],
          borderBottom: '1px solid',
          borderColor: 'grays.100',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'baseline',
          position: 'relative',
          color: placedOnImageBackground ? 'white' : ''
        } as object,
        sx
      )}
    >
      <Container
        sx={{
          px: [4, 3, 3, 0],
          py: [4, 8, 8, 0]
        }}
      >
        <Box
          sx={{
            flex: 1,
            pb: 0,
            mb: 0,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'row',
            position: ['relative', 'relative', 'relative', 'static']
          }}
        >
          <Heading
            as="h2"
            sx={{
              mb: [7, 7, 16, 2],
              flex: '1 1 100%',
              lineHeight: 'body',
              pr: [4, 4, 4, 0],
              fontSize: [5, 5, 6, 6]
            }}
          >
            {name}
          </Heading>
          <WishlistButton
            saleId={id}
            source="Intelligent Module"
            sx={{
              color: placedOnImageBackground ? 'white' : null,
              top: 1,
              right: ['0', '0', '0', 'auto'],
              left: ['auto', 'auto', 'auto', -3],
              display: 'block'
            }}
          />
          <Ratings
            average={averageScore}
            total={totalCount}
            onClick={() => scrollToElementId('reviews')}
            sx={{ mr: [9, 4, 8, 8], zIndex: 1 }}
          />
          <BundleInfo
            coursesQty={numberOfCourses}
            sx={{
              mt: 0,
              mr: [9, 4, 8, 8]
            }}
          />
          <SalePriceDropMessage
            expiresAt={expiresAt}
            applyRetailPrice={false}
            soldOut={soldOut}
          />
        </Box>
      </Container>
    </Flex>
  )
}

export default SaleHeader
