import gql from 'graphql-tag'
import { pathOr } from 'ramda'

import { TApolloClient } from 'src/types/apollo'
import SaleReviewsDTO from '../dto/SaleReviewsDTO'
import { SaleReviewsType } from './../types/saleReviews'

type SaleRatingsQuery = {
  saleId: number
  offset: number
  cursor: string
  apolloClient: TApolloClient
}

const SALE_RATINGS_QUERY = gql`
  query saleReviews($id: Int!, $offset: Int!, $cursor: String!) {
    sale(id: $id) {
      ratings(first: $offset, after: $cursor) {
        pageInfo {
          endCursor
          hasNextPage
        }
        averageScore
        totalCount
        edges {
          node {
            databaseId
            rating
            comment
            edited
            createdAt
            user {
              firstName
              lastName
              databaseId
              avatarUrl
            }
            saleRatingResponse {
              comment
              updatedAt
            }
            sale {
              slug
            }
          }
        }
      }
    }
  }
`

const find = async ({
  saleId,
  offset,
  cursor,
  apolloClient
}: SaleRatingsQuery): Promise<SaleReviewsType | null> => {
  const { data } = await apolloClient.query({
    query: SALE_RATINGS_QUERY,
    variables: { id: saleId, offset, cursor },
    fetchPolicy: 'cache-first'
  })

  const reviews = pathOr(null, ['sale', 'ratings'], data)

  return reviews ? SaleReviewsDTO.parse(reviews) : null
}

export default { find }
