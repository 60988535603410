import { Box } from 'rebass'
import Currency from '@atoms/Currency/Currency'
import Text from '@atoms/Text/Text'

export const fontBase = {
  fontSize: 1,
  fontFamily: 'body',
  lineHeight: '150%'
}

export const discounts = {
  color: 'grays.6',
  fontWeight: 'bold'
}

const styles = {
  fontBase,
  prices: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: ['grid', 'flex'],
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '0',
    gridTemplateAreas: '"price discount" "oldPrice discount"'
  },
  discountPrice: {
    color: 'grays.9',
    fontWeight: 'body',
    minWidth: ['55px', '65px'],
    fontSize: ['12px', 1],
    lineHeight: '20px',
    gridArea: 'price',
    textAlign: 'right',
    ml: [0, 2]
  },
  oldPrice: {
    ...discounts,
    fontWeight: 'body',
    textDecorationLine: 'line-through',
    minWidth: ['55px', '65px'],
    fontSize: ['12px', 1],
    lineHeight: '20px',
    gridArea: 'oldPrice',
    textAlign: ['right', 'left'],
    ml: [0, 2]
  },
  discount: {
    ...discounts,
    minWidth: ['50px', '58px'],
    fontSize: ['12px', 1],
    lineHeight: '20px',
    gridArea: 'discount',
    ml: 3
  }
}

export type Props = {
  priceInCents?: number
  retailPriceInCents?: number
  calculatedDiscount?: number
  sx?: object
}

const OptionRadioDiscount: React.FC<React.PropsWithChildren<Props>> = ({
  priceInCents = 0,
  retailPriceInCents = 0,
  calculatedDiscount = 0,
  sx = {},
  ...props
}) => {
  return (
    <Box sx={{ ...styles.prices, ...sx }} className="prices" {...props}>
      <Currency sx={{ ...styles.fontBase, ...styles.discountPrice }}>
        {priceInCents}
      </Currency>

      {calculatedDiscount > 0 && retailPriceInCents && (
        <Currency sx={{ ...styles.fontBase, ...styles.oldPrice }}>
          {retailPriceInCents}
        </Currency>
      )}

      {calculatedDiscount > 0 && (
        <Text sx={{ ...styles.fontBase, ...styles.discount }}>
          {`(${calculatedDiscount}% off)`}
        </Text>
      )}
    </Box>
  )
}

export default OptionRadioDiscount
