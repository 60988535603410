import Solo from './Solo'
import Bundle from './Bundle'
import { SaleDetailsProps } from '@concepts/Sales/types/sale'

const Recurring: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  return sale.products.length > 1 ? (
    <Bundle sale={sale} />
  ) : (
    <Solo sale={sale} />
  )
}

export default Recurring
