import { mergeDeepRight } from 'ramda'
import { Box, SxStyleProp } from 'rebass'
import {
  InputProps,
  Label as RebassLabel,
  LabelProps,
  Radio as RebassRadio,
  RadioProps as RebassRadioProps
} from '@rebass/forms'

import Text from '@atoms/Text/Text'

const Label = RebassLabel as React.FunctionComponent<LabelProps>
const TypedRadio = RebassRadio as React.FunctionComponent<RebassRadioProps>

const radio = {
  width: '1rem',
  height: '1rem',
  color: 'transparent',
  bg: 'transparent',
  border: '1px solid',
  borderColor: 'black',
  borderRadius: '50%'
}

const styles = {
  container: {
    svg: {
      margin: 0
    }
  },
  radio: {
    cursor: 'pointer',
    'input ~ &': {
      ...radio,
      transition: '0.2s ease borderWidth'
    },
    'input:checked ~ &': {
      ...radio,
      borderWidth: 5
    },
    'input:focus ~ &': {
      bg: 'grays.11'
    }
  },
  label: {
    '& > div': {
      minWidth: '16px',
      mr: 2
    }
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    pr: 2,
    fontSize: ['13px', 1],
    display: 'flex',
    alignItems: 'center'
  }
}

type RadioProps = InputProps & {
  id: string
  label?: string
  css?: string
  sxLabel?: object
  sxRadio?: object
  sxContainer?: SxStyleProp
  sxText?: object
}

const Radio = ({
  id,
  label = '',
  sxLabel = {},
  sxRadio = {},
  sxText = {},
  sxContainer = {},
  ...props
}: RadioProps) => {
  return (
    <Box sx={{ ...styles.container, ...sxContainer }}>
      <Label
        htmlFor={id}
        title={label}
        aria-label="label"
        sx={mergeDeepRight(styles.label, sxLabel)}
      >
        <TypedRadio
          sx={mergeDeepRight(styles.radio, sxRadio)}
          id={id}
          {...props}
        />
        {label && <Text sx={mergeDeepRight(styles.text, sxText)}>{label}</Text>}
      </Label>
    </Box>
  )
}

export default Radio
