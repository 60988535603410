import { Box, Flex, Text } from '@lib/UIComponents'
import { animated } from 'react-spring'

import { ChevronDownIcon } from '@atoms/Icon/Icon'
import { useReviewResponse } from './hooks/useReviewResponse'

const ReviewResponse = ({
  comment,
  date
}: {
  comment: string
  date: string
}) => {
  const { ref, handleClick, styledProps, transform, isOpen } =
    useReviewResponse()

  return (
    <Flex
      sx={{
        mx: 'auto',
        mt: 10,
        ml: [4, 6, 10],
        py: 3,
        px: 5,
        backgroundColor: '#F2F2F2',
        borderRadius: '15px',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
      onClick={handleClick}
      data-testid="expand-response"
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Flex>
          <Text sx={{ fontSize: ['sm', 'md'], fontWeight: 'bold' }}>
            Customer Support Response
          </Text>
        </Flex>

        <Flex
          sx={{
            pt: 1,
            fontSize: 'md'
          }}
        >
          <animated.span
            style={{ transform, cursor: 'pointer', lineHeight: 0 }}
            data-testid="expand-icon"
          >
            <ChevronDownIcon />
          </animated.span>
        </Flex>
      </Flex>

      <Box sx={{ pt: isOpen ? 4 : 0 }}>
        <animated.div
          style={{
            overflow: 'hidden',
            ...styledProps
          }}
          ref={ref}
        >
          <Flex
            sx={{
              width: '80%',
              flexDirection: 'column',
              gap: 4
            }}
          >
            <Text as="p" sx={{ fontSize: ['sm', 'md'] }}>
              {comment}
            </Text>

            <Text sx={{ fontSize: ['sm', 'md'], color: 'grays.600' }}>
              {date}
            </Text>
          </Flex>
        </animated.div>
      </Box>
    </Flex>
  )
}

export default ReviewResponse
