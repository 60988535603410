import { isEmpty } from 'ramda'
import { Box, Flex } from 'rebass'

import Text from '@atoms/Text/Text'
import { toMoney } from '@utils/numbers'

export type PriceTableItem = {
  name: string
  price: number
}

export type PriceTableType = {
  priceList: PriceTableItem[]
  total: number
}

const styles = {
  priceItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 5,
    px: 3,
    span: {
      fontSize: [1, 1],
      color: 'grays.8'
    },
    '&:not(:last-of-type)': {
      borderBottom: '1px solid',
      borderColor: 'grays.2'
    },
    '&:last-of-type': {
      span: {
        fontWeight: 'bold',
        '&:last-of-type': {
          textDecoration: 'line-through'
        }
      }
    }
  }
}

const PriceTable: React.FC<React.PropsWithChildren<PriceTableType>> = ({
  priceList,
  total
}) => {
  if (isEmpty(priceList)) return null

  return (
    <Box
      as="ul"
      sx={{
        listStyleType: 'none',
        padding: 0,
        mb: 6
      }}
    >
      {priceList.map((item, index) => (
        <Flex
          as="li"
          key={index}
          sx={styles.priceItem}
          data-testid="recurringPriceItem"
        >
          <Text>{item.name}</Text>
          <Text
            sx={{
              flex: '1 0 auto',
              minWidth: '30%',
              textAlign: 'right'
            }}
          >
            ${toMoney(item.price)}/mo.
          </Text>
        </Flex>
      ))}

      <Flex as="li" sx={styles.priceItem}>
        <Text>Total</Text>
        <Text
          sx={{
            flex: '1 0 auto',
            minWidth: '30%',
            textAlign: 'right'
          }}
        >
          ${toMoney(total)}/mo.
        </Text>
      </Flex>
    </Box>
  )
}

export default PriceTable
