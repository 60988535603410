import { ReactNode } from 'react'
import { Flex, Box } from 'rebass'

import Avatar from '@atoms/Avatar/Avatar'
import Text from '@atoms/Text/Text'

type Props = {
  author: string
  description: ReactNode
  backgroundColor?: string
}

const styles = {
  column: {
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '640px',
    m: '0 auto',
    px: [5, 5, 2],
    py: ['40px', 9, 9, '80px']
  },
  author: {
    fontSize: [4, 5, 6],
    fontWeight: 'heading',
    lineHeight: 'heading',
    my: 5
  },
  description: {
    fontSize: 2,
    my: 0,
    a: {
      color: 'primary',
      transition: 'opacity 250ms ease-in-out',
      '&:hover': {
        opacity: 0.8
      }
    }
  }
}

const Instructor: React.FC<React.PropsWithChildren<Props>> = ({
  author,
  description,
  backgroundColor = 'primaries.0',
  ...props
}) => {
  const firstName = author.split(' ')[0]
  const lastName = author.split(' ')[1]

  return (
    <Box sx={{ backgroundColor }} {...props}>
      <Flex sx={styles.column}>
        <Avatar
          size="large"
          alt="avatar"
          firstName={firstName}
          lastName={lastName}
          fontSize={['23px', '23px', '40px']}
        />
        <Text as="strong" sx={styles.author}>
          {author}
        </Text>
        <Text sx={styles.description}>{description}</Text>
      </Flex>
    </Box>
  )
}

export default Instructor
