import { mergeDeepRight } from 'ramda'

import Container from '@atoms/UIContainer/Container'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import SaleGallery from '@molecules/SaleGallery/SaleGallery'
import SaleSummary from '@molecules/SaleSummary/SaleSummary'
import { isPresent } from '@utils/logic'
import TLDRList from '@molecules/TLDRList/TLDRList'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'
import { TLDRItemProps } from '@atoms/TLDRItem/TLDRitem'
import { SaleSummaryStyle } from '@molecules/SaleSummary/utils'

export type BundleSaleHeaderProps = {
  sale: SaleDetailsType
  bgColor?: string
  showGalleryOnMobile?: boolean
  sx?: UIStyles
}

const BundleSaleHeader = ({
  sale,
  bgColor = 'grays.60',
  showGalleryOnMobile = false,
  sx = {}
}: BundleSaleHeaderProps) => {
  const isBundleSale =
    sale.type === SaleTypeEnum.Bundle || sale.type === SaleTypeEnum.BigBundle
  const FeatureFlag = useFeatureFlag()
  const shouldDisplaySaleBannerImg =
    isBundleSale &&
    FeatureFlag.isEnabled('ssr_use_sale_banner', `sale:${sale.id}`) &&
    isPresent(sale.specialSaleBannerBgUrl)

  return (
    <>
      <Box
        sx={mergeDeepRight(
          {
            color: shouldDisplaySaleBannerImg ? 'white' : '',
            backgroundColor: bgColor,
            backgroundImage: shouldDisplaySaleBannerImg
              ? `url(${sale.specialSaleBannerBgUrl})`
              : null,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          } as object,
          sx
        )}
      >
        <Container
          withDefaultPadding
          sx={{
            pt: [4, 8, 8, 16],
            pb:
              sale.recurringProps.length > 0
                ? ['100px', '120px', '176px']
                : [4, 8, 10]
          }}
        >
          {showGalleryOnMobile && (
            <SaleGallery
              saleName={sale.name}
              mediaList={sale.firstProduct?.medias || []}
              sx={{
                mb: 4,
                display: ['block', 'block', 'block', 'none', 'none']
              }}
            />
          )}

          <SaleSummary
            sale={sale}
            variation={SaleSummaryStyle.Bundle}
            placedOnImageBackground={shouldDisplaySaleBannerImg}
          />
        </Container>
      </Box>

      {sale.recurringProps.length > 0 && (
        <Container
          withDefaultPadding
          sx={{ py: 0, mt: ['-80px', '-80px', '-128px'] }}
        >
          <TLDRList list={sale.recurringProps as TLDRItemProps[]} />
        </Container>
      )}
    </>
  )
}

export default BundleSaleHeader
