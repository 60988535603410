import { useEffect } from 'react'
import { Box } from 'rebass'
import { isEmpty } from 'ramda'
import { Publisher } from 'src/generated/graphql'
import Container from '@atoms/Container/Container'
import SingleLineSeparator from '@atoms/Separator/SingleLineSeparator'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import useRecentlyViewedSales from '@concepts/RecentlyViewedSales/hooks/useRecentlyViewedSales'
import RecentlyViewedSales from '@concepts/RecentlyViewedSales/views/RecentlyViewedSales'
import RelatedSales from '@concepts/RelatedSales/views/RelatedSales'
import SaleReviews from '@concepts/SaleReviews/views/SaleReviews'
import { SaleDetailsType, SaleTypeEnum } from '@concepts/Sales/types/sale'
import SaleType from '@concepts/Sales/views/sale-types/SaleType'
import SalePageSchema from '@concepts/Seo/schemas/SalePage'
import SaleFullDescription from '@molecules/SaleFullDescription/SaleFullDescription'
import SaleTabs from '@molecules/SaleTabs/SaleTabs'
import { isPresent } from '@utils/logic'
import { useSaleContext } from '@concepts/Sales/store/saleContext'
import RecurringSaleSection from '@organisms/RecurringSaleSection/RecurringSaleSection'
import TLDRList from '@molecules/TLDRList/TLDRList'
import { TLDRItemProps } from '@atoms/TLDRItem/TLDRitem'
import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import { isClient } from '@utils/env'

const shouldDisplaySaleTabs = (sale: SaleDetailsType) => {
  return (
    sale.type === SaleTypeEnum.Solo ||
    sale.type === SaleTypeEnum.SoloVariant ||
    (sale.type === SaleTypeEnum.Freebie && sale.products.length === 1) ||
    (sale.type === SaleTypeEnum.Recurring && sale.products.length === 1)
  )
}

const SaleDetails = () => {
  const publisher = usePublisherContext()
  const { sale } = useSaleContext()
  const { sales: recentlyViewedSales } = useRecentlyViewedSales({
    saleId: sale.id
  })

  const hasReviews = !isEmpty(sale.saleReviews.reviews)

  const hasCourse = sale.products.some((product) => product.course)
  const isRecurringSale = sale.type === SaleTypeEnum.Recurring

  /* istanbul ignore next */
  useEffect(() => {
    if (isClient() && sale) {
      ecommerceAnalytics.trackProductDetails(
        sale,
        `${sale.type.toLowerCase()} page`
      )
    }
  }, [sale])

  const tabs = [
    {
      label: 'Description',
      value: 'description',
      hidden: !sale.title
    },
    {
      label: 'Instructor',
      value: 'instructor',
      hidden: !hasCourse || isRecurringSale
    },
    {
      label: 'Important Details',
      value: 'importantDetails'
    },
    {
      label: 'Reviews',
      value: 'reviews',
      hidden: !(sale.saleReviews.totalCount > 0)
    },
    {
      label: 'Related Products',
      value: 'relatedProducts'
    }
  ].filter((tab) => !tab.hidden)

  const displaySaleTabs = shouldDisplaySaleTabs(sale)
  const hideMobileTab = sale.type === SaleTypeEnum.Freebie

  return (
    <Box mb={isPresent(recentlyViewedSales) ? '0' : 9}>
      <SalePageSchema sale={sale} publisher={publisher as Publisher} />

      <SaleType sale={sale} />

      {displaySaleTabs && (
        <>
          <SaleTabs tabs={tabs} sale={sale} hideMobileTab={hideMobileTab} />
          {sale.recurringProps.length > 0 && (
            <Box sx={{ backgroundColor: 'primaries.0', mb: 9 }}>
              <Container
                withDefaultPadding
                sx={{ py: 0, position: 'relative', top: 9 }}
              >
                <TLDRList list={sale.recurringProps as TLDRItemProps[]} />
              </Container>
            </Box>
          )}
          <SaleFullDescription sale={sale} />
          <SingleLineSeparator />
        </>
      )}

      {isRecurringSale && <RecurringSaleSection sale={sale} />}

      {hasReviews && (
        <>
          <SaleReviews
            saleId={sale.id}
            initialReviews={sale.saleReviews.reviews}
            initialPageInfo={sale.saleReviews.pageInfo}
            averageRating={sale.saleReviews.averageScore}
            reviewsCount={sale.saleReviews.totalCount}
          />
          <SingleLineSeparator />
        </>
      )}

      <RelatedSales
        saleIds={[sale.id]}
        sx={{
          pt: [5, 9],
          px: 2,
          minWidth: 'auto'
        }}
      />

      {isPresent(recentlyViewedSales) && (
        <Container
          withDefaultPadding
          sx={{
            pt: [4, 4, 9],
            pb: [10, 10, '100px']
          }}
        >
          <Box id="recentProducts">
            <RecentlyViewedSales sales={recentlyViewedSales} />
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default SaleDetails
