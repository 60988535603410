import { pathOr } from 'ramda'

import { Box } from '@lib/UIComponents'

type Props = {
  children?: React.ReactNode
  countries: string
}

const styles = {
  list: {
    'li:empty': {
      display: 'none'
    }
  }
}

const ShippingList = ({ children, countries }: Props) => {
  const shippingBody = pathOr(
    { props: { children: '' } },
    ['props', 'children'],
    children
  )

  return (
    <Box as="ul" sx={styles.list}>
      <Box as="li">{countries}</Box>

      {Array.isArray(shippingBody) ? (
        shippingBody.map(({ props }, index) => (
          <Box as="li" key={index}>
            {props?.children}
          </Box>
        ))
      ) : (
        <Box as="li">{shippingBody.props.children}</Box>
      )}
    </Box>
  )
}

export default ShippingList
