import Heading from '@atoms/Heading/Heading'
import ImagePicker from '@atoms/ImagePicker/ImagePicker'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { Box } from '@lib/UIComponents'

export type ListImagesProps = {
  title: string
  selected: string
  items: Array<Omit<SaleDetailsType, 'id'> & { id: string }>
}

const styles = {
  container: {
    display: 'block',
    width: '100%',
    mb: 7,
    pb: 7,
    borderBottom: '1px solid',
    borderColor: 'grays.100',
    a: {
      display: 'inline-flex',
      mr: '2',
      mb: '2'
    }
  }
}

const ListImages = ({ items, title, selected }: ListImagesProps) => {
  return (
    <>
      {items && (
        <Box sx={styles.container}>
          <Heading level={4} mb={4}>
            {title}
          </Heading>

          {items.map((sale) => {
            const firstProduct = sale.firstProduct

            return (
              firstProduct?.variantImage?.url && (
                <ImagePicker
                  key={sale.id}
                  id={sale.id.toString()}
                  imageSrc={sale.imageSrc as string}
                  alt={firstProduct.variantImage.altText || sale.name}
                  slug={sale.slug}
                  soldOut={sale.soldOut}
                  checked={selected === sale.slug}
                />
              )
            )
          })}
        </Box>
      )}
    </>
  )
}

export default ListImages
