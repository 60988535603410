/* istanbul ignore next */

import Heading from '@atoms/UIHeading/Heading'
import { StarOutline, TrophyIcon, AwardIcon } from '@atoms/Icon/Icon'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type HowItWorksProps = {
  placedOnImageBackground?: boolean
  sx?: UIStyles
}

const content = [
  {
    icon: <StarOutline />,
    text: "Beat the Leader's price and get featured on the Leaderboard!"
  },
  {
    icon: <TrophyIcon />,
    text: "Beat the average price and you'll take home the entire bundle."
  },
  {
    icon: <AwardIcon />,
    text: "If what you pay is less than the average, you'll still take home something great."
  }
]

const HowItWorks = ({
  placedOnImageBackground = false,
  sx = {}
}: HowItWorksProps) => {
  return (
    <Box sx={sx}>
      <Heading as="h2" sx={{ pb: 5 }}>
        How It Works
      </Heading>

      <Box
        sx={{
          display: ['block', 'block', 'block', 'grid'],
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: 6
        }}
      >
        {content.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: ['grid', 'grid', 'block'],
              gridTemplateColumns: 'auto 1fr',
              gridGap: [4, 6],
              mt: [8, 8, 8, 0],
              '&:first-of-type': {
                mt: 0
              },
              '& > svg': {
                color: placedOnImageBackground
                  ? ['blacks.500', 'blacks.500', 'blacks.500', 'white']
                  : 'blacks.500',
                fontSize: 6,
                mb: [0, 2]
              }
            }}
          >
            {item.icon}
            <Text
              as="p"
              sx={{
                maxWidth: ['100%', '100%', '60%', 'none']
              }}
            >
              {item.text}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default HowItWorks
