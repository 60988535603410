import { useRef, useState } from 'react'
import { useSpring } from 'react-spring'

const useReviewResponse = () => {
  const ref = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(true)

  const { transform, ...props } = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    maxHeight: isOpen ? '9999px' : '0',
    config: { mass: 1, tension: 500, friction: 50 }
  })

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return { ref, handleClick, styledProps: props, transform, isOpen }
}

export { useReviewResponse }
