import { VerifiedIcon } from '@atoms/Icon/Icon'
import { Box, Icon, Text } from '@lib/UIComponents'

type DisclaimerProps = {
  text: string
  sx?: object
}

const styles = {
  default: {
    display: 'flex',
    color: 'blacks.0',
    alignItems: ['flex-start', 'flex-start', 'center']
  },
  text: {
    fontSize: ['lg', 'lg', 'lg', '24px']
  },
  icon: {
    mr: 3,
    position: 'relative',
    minWidth: '24px',
    display: 'flex',
    top: ['2px', '0'],
    svg: {
      fontSize: '24px'
    }
  }
}

const Disclaimer = ({ text, sx }: DisclaimerProps) => {
  return (
    <Box sx={{ ...styles.default, ...sx }}>
      <Box
        sx={{
          mr: 3,
          position: 'relative',
          minWidth: '24px',
          display: 'flex',
          top: ['2px', 0]
        }}
      >
        <Icon as={VerifiedIcon} sx={{ fontSize: '2xl' }} />
      </Box>

      <Text as="span" sx={{ fontSize: ['lg', 'lg', 'lg', '2xl'] }}>
        {text}
      </Text>
    </Box>
  )
}

export default Disclaimer
