import { isEmpty } from 'ramda'

import Heading from '@atoms/UIHeading/Heading'
import TextList, { TextListItem } from '@atoms/TextList/TextList'
import { Box } from '@lib/UIComponents'

export type FaqsProps = {
  questions: TextListItem[]
}

const Faqs = ({ questions }: FaqsProps) => {
  if (isEmpty(questions)) return null

  return (
    <Box>
      <Heading
        sx={{
          mb: [6, 6, 12]
        }}
        as="h3"
      >
        FAQS
      </Heading>

      <TextList list={questions} />
    </Box>
  )
}

export default Faqs
