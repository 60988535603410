import Container from '@atoms/UIContainer/Container'
import Faqs from '@molecules/Faqs/Faqs'
import ChooseRecurringPlan from '@organisms/ChooseRecurringPlan/ChooseRecurringPlan'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { Box, Flex } from '@lib/UIComponents'

type RecurringSaleSectionProps = {
  sale: SaleDetailsType
}

const columns = {
  pt: [0, 0, 0, 3],
  '&:first-of-type': {
    pr: [0, 0, 0, '100px'],
    mb: [8, 0],
    borderRightWidth: [0, 0, 0, 1],
    borderRightStyle: 'solid',
    borderColor: 'grays.100'
  },
  '&:last-of-type': {
    pl: [0, 0, 0, '130px']
  }
}

const questions = [
  {
    title: 'When will I be billed?',
    body: 'Your account will be renewed on a monthly or annual basis (depending on which plan you choose) on your date-of-purchase. Monthly subscriptions purchased on the 31st of a given month will be billed on the last day of each month accordingly.'
  },
  {
    title: 'What if I want to cancel my subscription?',
    body: 'No worries. If you feel the product isn\'t meeting your needs, you can cancel anytime right from your user account under the "Subscriptions" tab hassle free.'
  },
  {
    title: 'How do I activate my subscription?',
    body: 'In the "Purchases" tab of your user account, you will be given a redemption code for each product in this bundle after making your purchase to redeem on the merchant\'s website. Detailed redemption instructions are also provided for each product in your "Purchases" tab.'
  }
]

const RecurringSaleSection = ({ sale }: RecurringSaleSectionProps) => {
  return (
    <Container
      withDefaultPadding
      sx={{
        pt: ['50px', '50px', '50px', '100px'],
        pb: ['30px', '30px', '30px', '105px']
      }}
    >
      <Flex
        sx={{
          px: [2, 2, 2, 12],
          flexDirection: ['column', 'column', 'column', 'row']
        }}
      >
        <Box sx={{ ...columns, width: ['100%', '100%', '100%', '55%'] }}>
          <Faqs questions={questions} />
        </Box>
        <Box
          sx={{ ...columns, width: ['100%', '100%', '100%', '45%'] }}
          id="choosePlanSection"
        >
          <ChooseRecurringPlan sale={sale} />
        </Box>
      </Flex>
    </Container>
  )
}

export default RecurringSaleSection
