import { ProductType, SaleDetailsProps } from '@concepts/Sales/types/sale'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'
import BundleSaleHeader from '@molecules/BundleSaleHeader/BundleSaleHeader'
import Container from '@atoms/Container/Container'
import { CourseOverview } from '@organisms/CourseOverview/CourseOverview'
import { CourseOutlineStyle } from '@molecules/CourseOutline/CourseOutline'

const Course: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  const styles = {
    header: {
      pb: ['210px', '200px']
    },
    container: {
      mt: ['-210px', '-210px', '-240px', '-210px', '-210px'],
      py: 0,
      pb: [0, 0, 0, '70px']
    },
    courseOverview: {
      p: 0
    }
  }

  return (
    <AddToCartProvider>
      <BundleSaleHeader
        sale={sale}
        showGalleryOnMobile
        bgColor="grays.18"
        sx={styles.header}
      />

      <Container withDefaultPadding sx={styles.container}>
        <CourseOverview
          hideGalleryOnMobile
          product={sale.firstProduct as ProductType}
          variation={CourseOutlineStyle.Course}
          sx={styles.courseOverview}
        />
      </Container>
    </AddToCartProvider>
  )
}

export default Course
