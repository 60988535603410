import Text, { Props as TextProps } from '@atoms/Text/Text'
import Spinner from '@atoms/Spinner/Spinner'

import { formatCents } from '@utils/strings'
import { usePublisherContext } from '@concepts/Publisher/store/context'

export type Props = TextProps & {
  children: number
  defaultTo?: string
  acceptsZero?: boolean
  monthly?: boolean
  loading?: boolean
}

const isPositive = (value: number): boolean => value > 0

const Currency: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  loading,
  ...props
}) => {
  const { currency } = usePublisherContext()

  const code = currency && currency.code !== 'USD' ? ` ${currency.code}` : ''

  return loading ? (
    <Spinner alt="Calculating..." />
  ) : (
    <Text {...props}>
      {isPositive(children) || props.acceptsZero
        ? formatCents(children) + code
        : props.defaultTo}
      {props.monthly && '/mo'}
    </Text>
  )
}

export default Currency
