import { SaleRatings } from 'src/generated/graphql'

import { SaleReviewsType } from '../types/saleReviews'
import { Review } from '@molecules/ReviewItem/ReviewItem'

const parse = (saleReviews: SaleRatings): SaleReviewsType => {
  /* istanbul ignore next */
  return {
    reviews: (saleReviews?.edges?.map((item) => item?.node) as Review[]) || [],
    pageInfo: saleReviews?.pageInfo || null,
    averageScore: saleReviews?.averageScore || 0,
    totalCount: saleReviews?.totalCount || 0
  }
}

export default { parse }
