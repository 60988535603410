import Avatar from '@atoms/Avatar/Avatar'
import { UserStarChampionIcon, UserStarIcon } from '@atoms/Icon/Icon'
import Currency from '@atoms/UICurrency/Currency'
import { Box, Flex, Text } from '@lib/UIComponents'

export interface LeaderboardChampionProps {
  rank: number
  name: string
  avatarSrc: string
  paidPriceCents: number
}

const styles = {
  name: {
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'xl',
    lineHeight: '150%',
    color: 'blacks.500',
    alignItems: 'baseline'
  },
  title: {
    fontSize: [4, 5],
    lineHeight: '1',
    fontWeight: 'bold',
    color: 'blacks.500'
  },
  wrapper: {
    pt: 5
  },
  icon: {
    display: ['none', 'none', 'inline-block'],
    color: 'highlight',
    fontSize: 6,
    verticalAlign: 'middle',
    mr: 4,
    mobile: {
      display: ['block', 'block', 'none'],
      position: 'absolute',
      color: 'highlight',
      fontSize: '40px',
      verticalAlign: 'middle',
      right: '-19px',
      bottom: '-18px'
    }
  }
}

const LeaderboardChampion = ({
  name,
  avatarSrc,
  paidPriceCents
}: LeaderboardChampionProps) => {
  const firstName = name.split(' ')[0]
  const lastName = name.split(' ')[1]

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Avatar
          size={{ width: 79, height: 79 }}
          alt={name}
          src={avatarSrc}
          onlyMobile
          highlight
          firstName={firstName}
          lastName={lastName}
          fontSize={['30px', '30px']}
        />
        <Box as="span" sx={styles.icon.mobile}>
          <UserStarChampionIcon data-testid="championStarIconMobile" />
        </Box>
      </Box>
      <Flex sx={styles.wrapper}>
        <Text as="p" sx={styles.name}>
          {name}
        </Text>
        <Currency sx={{ ...styles.name, ml: 1, fontWeight: 'body' }}>
          {paidPriceCents}
        </Currency>
      </Flex>
      <Text as="h3" sx={styles.title}>
        <Box as="span" sx={styles.icon}>
          <UserStarIcon data-testid="championStarIcon" />
        </Box>
        Leaderboard Champion
      </Text>
    </Flex>
  )
}

export default LeaderboardChampion
