import { FC, useMemo, useState, useCallback } from 'react'
import { useAuthApollo } from '@lib/apollo'
import { SaleContext } from './saleContext'
import { SaleDetailsType } from '../types/sale'
import { updateProduct } from '../dto/SaleDetailsDTO'
import ProductRepository from '../repository/ProductRepository'

type SaleProviderProps = {
  sale: SaleDetailsType
  children: React.ReactNode
}

const SaleProvider: FC<React.PropsWithChildren<SaleProviderProps>> = ({
  children,
  ...props
}) => {
  const apolloClient = useAuthApollo()

  const [sale, setSale] = useState(props.sale)

  const findProductDetails = useCallback(
    async (id: string) => {
      const productDetails = await ProductRepository.findProductDetails(
        id,
        apolloClient
      )

      if (!productDetails) {
        return
      }

      setSale((prevSale) => updateProduct(prevSale, productDetails))
    },
    [apolloClient]
  )

  const value = useMemo(
    () => ({
      sale,
      findProductDetails
    }),
    [sale, findProductDetails]
  )

  return <SaleContext.Provider value={value}>{children}</SaleContext.Provider>
}

export default SaleProvider
