import SaleReviewsDTO from '@concepts/SaleReviews/dto/SaleReviewsDTO'
import { Media } from '@molecules/SaleGallery/SaleGallery'
import { head, mergeDeepLeft, pathOr, reject, remove } from 'ramda'
import {
  Brand,
  Maybe,
  Product,
  RecurringSaleGroup,
  Sale,
  SaleGroup,
  SaleRatings
} from 'src/generated/graphql'
import { ProductType, SaleDetailsType } from '../types/sale'
import ProductDTO from './ProductDTO'
import recurringSaleGroupDTO from './RecurringSaleGroupDTO'
import SaleGroupDTO from './SaleGroupDTO'

export const parse = (sale: Sale, rejectUndefined = false): SaleDetailsType => {
  const firstProductFromQuery = head(
    pathOr([], ['firstProduct', 'nodes'], sale) as unknown as Array<Product>
  ) as Maybe<Product>

  const productNodes = pathOr(
    [],
    ['products', 'nodes'],
    sale
  ) as unknown as Array<Product>

  const products = firstProductFromQuery
    ? [firstProductFromQuery, ...remove(0, 1, productNodes)].map(
        ProductDTO.parse
      )
    : productNodes.map(ProductDTO.parse)

  const firstProduct = firstProductFromQuery
    ? ProductDTO.parse(firstProductFromQuery)
    : head(products)

  const imageSrc: string | null = pathOr(
    null,
    ['variantImage', 'pictureAttributes', 'source_tags', 0, 'srcset'],
    firstProduct
  )

  const medias: Media[] = firstProduct?.medias as Media[]

  const isInvalid = (value: unknown) => value === undefined && rejectUndefined

  return reject(isInvalid, {
    id: sale.databaseId as number,
    name: sale.name,
    title: sale.title,
    metaDescription: sale.metaDescription,
    terms: sale.terms,
    tabs: sale.tabs,
    imageCollection: sale.imageCollection,
    freeShippingMessage: sale.freeShippingMessage,
    customSalePageTitle: sale.customSalePageTitle,
    products,
    shippingMessage: sale.shippingMessage,
    priceInCents: sale.priceInCents,
    retailPriceInCents: sale.retailPriceInCents,
    preDropPriceInCents: sale.preDropPriceInCents,
    priceToBeatAverageCents: sale.priceToBeatAverageCents,
    nyopMinimumToMiniCents: sale.nyopMinimumToMiniCents,
    nyopAveragePriceToken: sale.nyopAveragePriceToken,
    calculatedDiscount: sale.calculatedDiscount as number,
    preDropPrice: sale.preDropPrice,
    priceDescription: sale.priceDescription,
    priceDropDescription: sale.priceDropDescription,
    miniBundle: pathOr(null, ['products', 'nodes'], sale.miniBundle),
    shipsFast: sale.shipsFast,
    ships: sale.ships,
    shippableCountries: sale.shippableCountries,
    interval: sale.interval,
    expiresAt: sale.expiresAt,
    expired: sale.expired,
    isActive: sale.isActive,
    isPrivate: sale.isPrivate,
    noindex: sale.noindex,
    category: sale.category,
    soldOut: sale.soldOut,
    group: SaleGroupDTO(sale.saleGroup as SaleGroup),
    slug: sale.slug,
    firstProduct,
    medias,
    imageSrc,
    maxLeadTime: sale.maxLeadTime,
    maxPerUser: sale.maxPerUser,
    maxForCurrentUser: sale.maxForCurrentUser,
    maxAvailable: sale.maxAvailable,
    affiliateUrl: sale.affiliateUrl,
    fromAffiliate: sale.fromAffiliate,
    affiliatePriceText: sale.affiliatePriceText,
    affiliateBuyButtonText: sale.affiliateBuyButtonText,
    vendorName: firstProduct?.vendorName,
    saleWarranties: sale.saleWarranties,
    brand: pathOr(null, ['brands', 0], sale) as Brand | null,
    saleGroupOption: sale.saleGroupOption,
    priceValidUntil: sale.priceValidUntil,
    breadcrumb: sale.breadcrumb,
    shippingCostInCents: sale.shippingCostInCents,
    specialSaleBannerBgUrl: sale.specialSaleBannerBg?.url ?? null,
    type: sale.type?.name,
    numberOfLessons: sale.numberOfLessons,
    numberOfCourses: sale.numberOfCourses,
    totalHours: sale.totalHours,
    numSold: sale.numSold,
    leaderboard: sale.leaderboard,
    recurringInterval: sale.recurringInterval,
    isRecurringTrial: sale.isRecurringTrial,
    recurringTrialPeriodDays: sale.recurringTrialPeriodDays,
    recurringSaleIntervalPrice: sale.recurringSaleIntervalPrice,
    recurringSaleGroup: recurringSaleGroupDTO(
      sale.recurringSaleGroup as RecurringSaleGroup
    ),
    recurringProps: sale.recurringProps,
    useCourseLayout: sale.useCourseLayout,
    priceDropEndsAt: sale.priceDropEndsAt,
    saleReviews: SaleReviewsDTO.parse(sale.ratings as SaleRatings),
    canonicalUrl: sale.canonicalUrl
  }) as unknown as SaleDetailsType
}

export const updateProduct = (
  sale: SaleDetailsType,
  productDetails: ProductType
): SaleDetailsType => ({
  ...sale,
  products: sale.products.map((product) => {
    if (product.id !== productDetails.id) {
      return product
    }

    return mergeDeepLeft(product, productDetails) as ProductType
  })
})

export default { parse }
