import { Box } from '@lib/UIComponents'

type ListContainerProps = {
  children: React.ReactNode
}

const ListContainer = ({ children }: ListContainerProps) => {
  return (
    <Box
      as="ul"
      sx={{
        listStyleType: 'none',
        padding: 0
      }}
    >
      {children}
    </Box>
  )
}

export default ListContainer
