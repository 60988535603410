import { pluralize } from '@utils/strings'

import { ShoppingCartIcon } from '@atoms/Icon/Icon'
import { Flex, Text } from '@lib/UIComponents'

type AddedToCartCounterProps = {
  counter: number
}

const AddedToCartCounter = ({ counter }: AddedToCartCounterProps) => {
  return (
    <Flex>
      <ShoppingCartIcon />
      <Text as="p" sx={{ fontSize: 'sm', ml: 2 }}>
        Added to cart {counter} {pluralize(counter, 'time', 'times')}
      </Text>
    </Flex>
  )
}

export default AddedToCartCounter
