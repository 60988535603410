/* istanbul ignore file */
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser'
import { mergeDeepRight } from 'ramda'

import ExpandableListItem from '@atoms/ExpandableListItem/ExpandableListItem'
import { CourseOutlineStyle } from '@molecules/CourseOutline/CourseOutline'
import { Box } from '@lib/UIComponents'

export type ExpandableListProps = {
  htmlText: string
  handleToggle: Function
  activeItem: number | null
  variation?: CourseOutlineStyle
}

const filteredItems = (domNode: Element) => {
  return (domNode.children as unknown as Element[]).filter(
    (node) => node.type === 'tag'
  )
}

const getTitle = (item: Element[]) => item.filter((i) => i.name !== 'ul').flat()

const getData = (item: Element[]) => item.filter((i) => i.name === 'ul').flat()

const getStyles = (variation: CourseOutlineStyle) => {
  const bundleStyles = {
    list: {
      padding: 0
    }
  }

  const courseStyles = {
    list: {
      'li:first-of-type span': {
        pt: 0
      },
      'li:last-of-type div': {
        borderBottom: 0
      }
    }
  }

  switch (variation) {
    case CourseOutlineStyle.Course:
      return mergeDeepRight(bundleStyles, courseStyles)

    default:
      return bundleStyles
  }
}

const ExpandableList = ({
  htmlText,
  handleToggle,
  activeItem,
  variation = CourseOutlineStyle.Bundle
}: ExpandableListProps) => {
  const styles = getStyles(variation)

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.name === 'ul' &&
        !domNode.prev
      ) {
        const items = filteredItems(domNode)

        return (
          <Box as="ul" sx={styles.list}>
            {items.map((item, index) => (
              <ExpandableListItem
                key={index}
                id={index}
                title={getTitle(item.children as unknown as Element[])}
                content={getData(item.children as unknown as Element[])}
                onClick={handleToggle}
                activeItem={activeItem}
                variation={variation}
              />
            ))}
          </Box>
        )
      }
    }
  }

  return <Box>{parse(htmlText, options)}</Box>
}

export default ExpandableList
