import { useMemo, useState } from 'react'
import { PwywContext, PriceOfferType } from './pwywContext'

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [pwywPrice, setPwywPrice] = useState(100)
  const [priceOffer, setPriceOffer] = useState<PriceOfferType>('averagePrice')

  const value = useMemo(
    () => ({ pwywPrice, setPwywPrice, priceOffer, setPriceOffer }),
    [pwywPrice, setPwywPrice, priceOffer, setPriceOffer]
  )

  return <PwywContext.Provider value={value}>{children}</PwywContext.Provider>
}

export default Provider
