import { useRouter } from 'next/router'

import ListImages from '@atoms/ListImages/ListImages'
import { SaleGroupType } from '@concepts/Sales/dto/SaleGroupDTO'
import { RadioType } from '@molecules/OptionRadio/OptionRadio'
import NumberPickerOptions from '@molecules/NumberPickerOptions/NumberPickerOptions'
import MultilineRadio from '@molecules/MultilineRadio/MultilineRadio'
import { Box } from '@lib/UIComponents'

type Props = {
  saleGroup: SaleGroupType
}

export enum SaleVariantType {
  Text = 'text',
  Image = 'image',
  Dropdown = 'dropdown'
}

const SaleVariantPicker = ({ saleGroup }: Props) => {
  const { query } = useRouter()
  const selectedVariant = `/sales/${query.slug}`

  if (!saleGroup) return null

  const data = {
    ...saleGroup,
    sales: saleGroup.sales.map((item) => ({
      ...item,
      name: item.saleGroupOption.name,
      id: item.id.toString(),
      slug: `/sales/${item.slug}`
    }))
  }

  return (
    <Box>
      {data.variantLayout === SaleVariantType.Text && (
        <MultilineRadio
          selected={selectedVariant}
          title={`Choose ${data.prompt}:`}
          items={data.sales}
          radioType={RadioType.DISCOUNT}
        />
      )}

      {data.variantLayout === SaleVariantType.Image && (
        <ListImages
          selected={selectedVariant}
          items={data.sales}
          title={`Choose ${data.prompt}:`}
        />
      )}

      {data.variantLayout === SaleVariantType.Dropdown && (
        <NumberPickerOptions
          selected={selectedVariant}
          title={`Choose ${data.prompt}:`}
          items={data.sales}
        />
      )}
    </Box>
  )
}

export default SaleVariantPicker
