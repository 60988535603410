import { Box } from 'rebass'

export type Props = {
  sx?: object
}

const OptionRadioPWYW: React.FC<React.PropsWithChildren<Props>> = ({
  sx = {},
  ...props
}) => {
  return (
    <Box sx={sx} className="prices" {...props}>
      PWYW Variant
    </Box>
  )
}

export default OptionRadioPWYW
