import { useState, useEffect, useCallback } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

type Size = {
  width: number
  height: number
}

const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [
  T | null,
  (node: T | null) => void,
  Size
] => {
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({ width: 0, height: 0 })

  const handleSetSize = useCallback(() => {
    setSize({
      width: ref?.scrollWidth || 0,
      height: ref?.scrollHeight || 0
    })
  }, [ref?.scrollWidth, ref?.scrollHeight])

  useEffect(() => {
    window.addEventListener('resize', handleSetSize)

    return () => {
      window.removeEventListener('resize', handleSetSize)
    }
  }, [handleSetSize])

  useIsomorphicLayoutEffect(() => {
    handleSetSize()
  }, [handleSetSize, ref?.scrollWidth, ref?.scrollHeight])

  return [ref, setRef, size]
}

export default useElementSize
