import { useAddToCartContext } from '@concepts/Sales/store/addToCartContext'
import UseLink from '@atoms/UILink/UseLink'
import { Box, Image } from '@lib/UIComponents'

export type ImagePickerProps = {
  id: string
  imageSrc: string
  alt: string
  checked?: boolean
  onClick?: () => void
  slug: string
  soldOut: boolean
}

const styles = {
  container: (checked: boolean) => ({
    display: 'inline-flex',
    borderRadius: 'default',
    border: '1px solid',
    borderColor: checked ? 'primary' : 'grays.100',
    color: 'primary',
    width: ['50px', '50px', '65px'],
    height: ['50px', '50px', '65px'],
    position: 'relative',
    my: 0,
    mx: 1,
    cursor: 'pointer',
    transition: 'border-color 250ms ease-in-out',
    '&:last-child': {
      mr: 0
    },
    '&:first-of-type': {
      ml: 0
    },
    '&:hover::before': {
      opacity: checked ? 1 : 0.4
    },
    '& > img': {
      borderRadius: 'default'
    },
    '::before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 'default',
      content: '" "',
      display: 'block',
      width: '100%',
      height: '100%',
      transition: 'opacity 250ms ease-in-out',
      boxShadow: '0 0 0 4px currentColor',
      opacity: checked ? 1 : 0
    }
  }),

  image: {
    objectFit: 'cover'
  },

  soldOut: {
    opacity: '0.5',
    pointerEvents: 'none',
    userSelect: 'none',
    display: 'inline-flex',
    width: ['50px', '50px', '65px'],
    height: ['50px', '50px', '65px'],
    position: 'relative',

    '::after': {
      background: `
      linear-gradient(to top right,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 1px),
        rgba(0,0,0,0.7) 50%,
        rgba(0,0,0,0) calc(50% + 1px),
        rgba(0,0,0,0) 100%)`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 'initial',
      content: '" "',
      display: 'block',
      width: '93%',
      height: '93%'
    }
  }
}

const ImagePicker = ({
  id,
  imageSrc,
  alt,
  onClick,
  checked = false,
  slug,
  soldOut
}: ImagePickerProps) => {
  const { setIsSubmitting } = useAddToCartContext()

  return (
    <UseLink
      onClick={() => setIsSubmitting(true)}
      useLink={Boolean(slug)}
      to={slug}
      sx={{ ...(soldOut && styles.soldOut) }}
    >
      <Box
        sx={styles.container(checked)}
        tabIndex={0}
        onClick={onClick}
        data-testid="imagePicker"
      >
        <Image
          sx={styles.image}
          id={id}
          src={imageSrc as string}
          alt={alt as string}
        />
      </Box>
    </UseLink>
  )
}

export default ImagePicker
