import Head from 'next/head'
import { isEmpty } from 'ramda'
import { Fragment } from 'react'

import { Category, Publisher } from 'src/generated/graphql'

import Link from '@atoms/Link/Link'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { toHttpsUrl } from '@utils/url'

type BreadcrumbProps = {
  breadcrumb: Category[]
  sx?: UIStyles
}

const toSchema = (publisher: Publisher, breadcrumb: Category[]) => {
  const hostnameWithoutSlash = toHttpsUrl(publisher.hostname as string).slice(
    0,
    -1
  )

  const itemList = breadcrumb.map((cat, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    item: {
      '@id': `${hostnameWithoutSlash}${cat.slug}`,
      name: cat.name
    }
  }))

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemList
  }
}

const Breadcrumb = ({ breadcrumb, sx = {} }: BreadcrumbProps) => {
  const publisher = usePublisherContext()

  if (!breadcrumb || isEmpty(breadcrumb)) return null

  const schema = toSchema(publisher as Publisher, breadcrumb)

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: `${JSON.stringify(schema)}` }}
        />
      </Head>

      <Box
        as="ul"
        sx={{
          display: 'flex',
          padding: 0,
          margin: 0,
          listStyleType: 'none',
          flexWrap: 'wrap',
          mb: [4, 6, 8],
          ...sx
        }}
      >
        {breadcrumb.map((bread, index) => (
          <Fragment key={index}>
            <Box as="li">
              <Link
                to={bread.slug as string}
                sx={{
                  color: 'primary',
                  fontSize: ['13px', 1],
                  lineHeight: '22px'
                }}
              >
                {bread.name}
              </Link>
            </Box>
            {index + 1 < breadcrumb.length && (
              <Text
                as="li"
                sx={{
                  color: 'grays.650',
                  mx: 2,
                  lineHeight: '22px'
                }}
              >
                ›
              </Text>
            )}
          </Fragment>
        ))}
      </Box>
    </>
  )
}

export default Breadcrumb
