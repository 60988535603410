import TLDRItem, { TLDRItemProps } from '@atoms/TLDRItem/TLDRitem'
import { Box } from '@lib/UIComponents'

export type TLDRListProps = {
  list: Array<TLDRItemProps>
}

const styles = (grid: number) => ({
  display: 'grid',
  gridGap: grid > 3 ? 4 : [4, 4, 4, 4, 9],
  justifyContent: 'center',
  gridTemplateColumns: [
    '1fr',
    grid > 3 ? '1fr 1fr' : '1fr',
    `repeat( ${grid}, minmax(210px, 1fr) )`
  ]
})

const TLDRList = ({ list }: TLDRListProps) => {
  const grid = list.length > 3 ? 4 : 3

  return (
    <Box sx={styles(grid)} data-testid="TLDRList">
      {list.map((item, index) => (
        <TLDRItem
          key={index}
          description={item.description}
          header={item.header}
        />
      ))}
    </Box>
  )
}

export default TLDRList
