import { ProductType } from '@concepts/Sales/types/sale'

type Product = Record<string, unknown> & { name: string }

export type ProductsWithLocked = { locked: boolean } & ProductType

const getLockedProducts = (
  products: Array<ProductType>,
  unlockedProducts: Array<Product>,
  isLocked: boolean
): Array<ProductsWithLocked> => {
  const unlockedProductsName = unlockedProducts.map(({ name }) => name)

  const productsWithActive = products.map((product) => ({
    ...product,
    locked: isLocked && !unlockedProductsName.includes(product.name)
  }))

  return productsWithActive.reverse()
}

export { getLockedProducts }
