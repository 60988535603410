import gql from 'graphql-tag'
import { pathOr } from 'ramda'
import { TApolloClient } from 'src/types/apollo'
import { ProductType } from '../types/sale'
import ProductDTO from '../dto/ProductDTO'

const PRODUCT_DETAILS_QUERY = gql`
  query ProductDetails($id: ID!) {
    product(id: $id) {
      id
      description
      videos
      specs
      instructor

      course {
        outline
        skillLevel
        certification
      }
    }
  }
`

export const findProductDetails = async (
  id: string,
  apolloClient: TApolloClient
): Promise<ProductType | null> => {
  const { data } = await apolloClient.query({
    query: PRODUCT_DETAILS_QUERY,
    variables: { id }
  })

  const productDetails = pathOr(null, ['product'], data)

  if (!productDetails) {
    return null
  }

  return ProductDTO.parse(productDetails)
}

export default { findProductDetails }
