import { useRef } from 'react'

import Currency from '@atoms/UICurrency/Currency'
import Input from '@atoms/UIInput/Input'
import Radio from '@atoms/Radio/Radio'
import { usePwywContext } from '@concepts/Sales/views/sale-types/store/pwywContext'
import { formatCents } from '@utils/strings'
import { Box, Text } from '@lib/UIComponents'

type PriceOfferItemType = {
  id: string
  title: string
  checked: boolean
  onClick: Function
  price?: number
  setBidPrice: Function
  hasInput?: boolean
  placedOnImageBackground?: boolean
}

const PriceOfferItem = ({
  id,
  checked,
  title,
  onClick,
  price,
  setBidPrice,
  placedOnImageBackground = false,
  hasInput = false
}: PriceOfferItemType) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { pwywPrice, setPwywPrice } = usePwywContext()

  const focusInput = () => {
    inputRef.current?.focus()

    setBidPrice(price)

    onClick(id)
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/\D/g, ''))

    setBidPrice(value)
    setPwywPrice(value)
  }

  const borderColor = checked ? 'blacks.500' : 'grays.50'
  const borderColorTwo = checked ? 'blacks.500' : 'transparent'
  const textColor = checked ? 'blacks.500' : 'grays.600'

  return (
    <Box
      sx={{
        cursor: 'pointer',
        p: 4,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: placedOnImageBackground
          ? [borderColor, borderColor, borderColor, 'white']
          : borderColor,
        borderRadius: 'default',
        color: 'grays.600',
        '&:first-of-type': {
          borderRadius: '0 0 5px 5px',
          mb: '10px',
          borderTopColor: placedOnImageBackground
            ? [borderColorTwo, borderColorTwo, borderColorTwo, 'white']
            : borderColorTwo
        }
      }}
      onClick={focusInput}
    >
      <Radio
        id={id}
        checked={checked}
        readOnly
        sxLabel={{
          mr: 2
        }}
        sxRadio={{
          'input ~ &': {
            borderColor: placedOnImageBackground
              ? ['black', 'black', 'black', 'white']
              : 'black'
          },
          'input:checked ~ &': {
            borderColor: placedOnImageBackground
              ? ['black', 'black', 'black', 'white']
              : 'black'
          }
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Text
          sx={{
            color: placedOnImageBackground
              ? [textColor, textColor, textColor, 'white']
              : textColor,
            fontSize: [1, 2, 3]
          }}
        >
          {title}
        </Text>
        {hasInput ? (
          <Input
            value={formatCents(pwywPrice)}
            onChange={onChange}
            forwardRef={inputRef}
            sx={{
              display: checked ? 'block' : 'none',
              mt: 2,
              maxWidth: '125px',
              input: {
                pt: 0,
                pb: 0,
                height: 8,
                '&:focus': {
                  color: 'blacks.50'
                }
              }
            }}
          />
        ) : (
          <Currency
            sx={{
              color: placedOnImageBackground ? ['', '', '', 'white'] : '',
              fontSize: [1, 2, 3]
            }}
          >
            {price as number}
          </Currency>
        )}
      </Box>
    </Box>
  )
}

export default PriceOfferItem
