import { useState } from 'react'
import { captureException } from '@sentry/react'

import { initializeApollo } from '@lib/apollo'
import { Review } from '@molecules/ReviewItem/ReviewItem'
import { PageInfo } from 'src/generated/graphql'
import SaleReviewsRepository from '../repository/SaleReviewsRepository'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { defaultMediaSizes } from 'src/components/theme/breakpoints'
import { SALE_REVIEWS_PER_PAGE } from '../views/SaleReviews'

export type useSaleReviewsReturnType = {
  newReviews: Review[]
  loading: boolean
  pageInfo: PageInfo | null
  getReviews: Function
}

export type useSaleReviewsType = {
  saleId: number
  initialPageInfo: PageInfo
}

const useSaleReviews = ({
  saleId,
  initialPageInfo
}: useSaleReviewsType): useSaleReviewsReturnType => {
  const publisher = usePublisherContext()
  const apolloClient = initializeApollo(
    {},
    { hostname: publisher.hostname as string }
  )
  const [loading, setLoading] = useState(false)
  const [newReviews, setNewReviews] = useState<Review[]>([])
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(initialPageInfo)

  const getReviews = () => {
    const offset =
      window.innerWidth >= defaultMediaSizes.ScreenM
        ? SALE_REVIEWS_PER_PAGE
        : SALE_REVIEWS_PER_PAGE / 2

    setLoading(true)

    /* istanbul ignore next */
    SaleReviewsRepository.find({
      saleId,
      offset,
      cursor: pageInfo?.endCursor as string,
      apolloClient
    })
      .then((data) => {
        /* istanbul ignore next */
        if (!data) return

        setPageInfo(data.pageInfo || null)
        setNewReviews([...newReviews, ...data.reviews])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        captureException(error)
      })
  }

  return {
    newReviews,
    pageInfo,
    loading,
    getReviews
  }
}

export default useSaleReviews
