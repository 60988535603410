import { mergeDeepRight, not } from 'ramda'
import { pluralize } from '@utils/strings'

import { Course } from 'src/generated/graphql'
import Checklist from '@atoms/Checklist/Checklist'
import CourseOutline, {
  CourseOutlineStyle
} from '@molecules/CourseOutline/CourseOutline'
import SaleGallery from '@molecules/SaleGallery/SaleGallery'
import { ProductType } from '@concepts/Sales/types/sale'
import useMediaQuery from '@hooks/useMediaQuery'
import { Box, Flex } from '@lib/UIComponents'
import { defaultMediaSizes } from '@lib/UIComponents/Theme/breakpoints'
import { UIStyles } from '@lib/UIComponents/types'

const styles = {
  wrapper: {
    px: [4, 4, '42px', '42px', '42px'],
    py: [4, 4, '40px', '40px', '40px']
  },
  row: {
    justifyContent: 'center',
    flexDirection: ['column', 'column', 'column', 'row', 'row'],
    alignItems: 'flex-start'
  },
  columns: {
    '&:first-of-type': {
      pr: [0, 0, 0, 6, 7]
    },
    '&:last-child': {
      pl: [0, 0, 0, 6, 7]
    }
  },
  gallery: (variant: CourseOutlineStyle) => ({
    borderRadius: '10px',
    overflow: 'hidden',
    mb: [3, 3, 3, 8, 9],
    mr:
      variant === CourseOutlineStyle.Bundle ? [0, 0, 0, 0, 9] : [0, 0, 0, 0, 4]
  }),
  checklist: {
    mb: [8, 8, 8, 0]
  }
}

export type CourseOverviewProps = {
  product: ProductType
  hideGalleryOnMobile?: boolean
  variation?: CourseOutlineStyle
  sx?: UIStyles
}

export const title = (numberOfLessons: number, duration: number): string => {
  const lessonPluralize = pluralize(numberOfLessons, 'Lesson', 'Lessons')
  const hourPluralize = pluralize(duration, 'hour', 'hours')

  const numberOfLessonsTitle = numberOfLessons
    ? `${numberOfLessons} ${lessonPluralize} `
    : ''
  const durationTitle =
    duration && numberOfLessons
      ? `(${duration}h)`
      : duration
        ? `${duration} ${hourPluralize} content`
        : ''
  return `${numberOfLessonsTitle}${durationTitle}`
}

const CourseOverview = ({
  product,
  variation = CourseOutlineStyle.Bundle,
  hideGalleryOnMobile = false,
  sx = {}
}: CourseOverviewProps) => {
  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)

  const { medias, name, course } = product

  const numberOfLessons = course?.numberOfLessons || 0
  const duration = course?.duration || 0
  const courseTitle = title(numberOfLessons, duration)
  const outline = course?.outline || ''

  return (
    <Box sx={mergeDeepRight(styles.wrapper, sx as object)}>
      <Flex sx={styles.row}>
        <Box
          sx={{
            ...styles.columns,
            width: ['100%', '100%', '100%', '63%', '63%']
          }}
        >
          {hideGalleryOnMobile ? (
            <>
              {not(isMobile) && (
                <SaleGallery
                  mediaList={medias}
                  saleName={name}
                  sx={styles.gallery(variation)}
                />
              )}
            </>
          ) : (
            <SaleGallery
              mediaList={medias}
              saleName={name}
              sx={styles.gallery(variation)}
            />
          )}

          {variation === CourseOutlineStyle.Bundle && (
            <Checklist course={course as Course} sx={styles.checklist} />
          )}
        </Box>
        {Boolean(outline) && (
          <Box
            sx={{
              ...styles.columns,
              width: ['100%', '100%', '100%', '37%', '37%']
            }}
          >
            <CourseOutline
              items={outline}
              title={courseTitle}
              maxHeight={[300, 600]}
              variation={variation}
            />
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export { CourseOverview }
