import { useState } from 'react'

import { toMoney } from '@utils/numbers'
import { DoneIcon } from '@atoms/Icon/Icon'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'
import useAddSaleToCart from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'
import SalePurchaseButton from '@molecules/SalePurchaseButton/SalePurchaseButton'
import PriceTable from '@molecules/PriceTable/PriceTable'
import { ExtractRecurringSaleGroupData } from './utils/ExtractRecurringSaleGroupData'
import { Box, Flex, Text } from '@lib/UIComponents'

export type Props = {
  sale: SaleDetailsType
}

const styles = {
  title: (isActiveTab: boolean) => ({
    display: 'inline',
    fontSize: [4, 4, 5],
    fontWeight: 'bold',
    lineHeight: 'button',
    borderBottom: 'solid 1px',
    borderColor: 'grays.150',
    pb: [3, 3, 4],
    px: [4, 5, 5, 6],
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'relative',
    '&:after': {
      content: '" "',
      width: '100%',
      height: '5px',
      backgroundColor: 'primary',
      position: 'absolute',
      bottom: '-3px',
      left: 0,
      transition: 'opacity 250ms ease-in-out',
      opacity: isActiveTab ? 1 : 0
    }
  })
}

const ChooseRecurringPlan = ({ sale }: Props) => {
  const [activeTab, setActiveTab] = useState(sale.recurringInterval)

  let currentSale

  const { annualSale, monthlySale, isChoosePlan } =
    ExtractRecurringSaleGroupData(sale.recurringSaleGroup)

  if (isChoosePlan) {
    currentSale = (
      activeTab === 'monthly' ? monthlySale : annualSale
    ) as SaleDetailsType
  } else {
    currentSale = sale
  }

  const { warrantyId } = useAddSaleToCart({
    sale
  })

  const isMonthly = currentSale.recurringInterval === 'monthly'
  const freeTrialDays = currentSale.recurringTrialPeriodDays
  const disclaimer = `Free trial expires after ${freeTrialDays} days after which you will be charged $${toMoney(
    currentSale.priceInCents
  )}/${isMonthly ? 'month' : 'year'}. Cancel anytime before trial expires.`

  const checkList = [
    {
      text: `${isMonthly ? '70%' : '75%'} off 4 premium security services`,
      disabled: false
    },
    {
      text: `${freeTrialDays}-Day Free trial`,
      disabled: false
    },
    { text: '2-month discount', disabled: isMonthly },
    { text: '20% off coupon for any future purchase', disabled: isMonthly }
  ]

  const priceList = sale.products.map((prod) => ({
    name: prod.name,
    price: prod.retailPriceInCents
  }))

  return (
    <Box>
      {isChoosePlan && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            textAlign: 'center',
            pb: [7, 7, 8]
          }}
        >
          <Text
            as="span"
            sx={styles.title(activeTab === 'annual')}
            onClick={() => setActiveTab('annual')}
          >
            Annual
          </Text>

          <Text
            as="span"
            sx={styles.title(activeTab === 'monthly')}
            onClick={() => setActiveTab('monthly')}
          >
            Monthly
          </Text>
        </Box>
      )}

      <Text
        as="p"
        sx={{
          fontSize: [6, 6, 8],
          fontWeight: 'bold',
          lineHeight: 'button',
          pt: 1,
          pb: [5, 5, 6],
          transition: 'transform .5s linear',
          textAlign: isChoosePlan ? 'left' : 'center'
        }}
      >
        ${toMoney(currentSale.recurringSaleIntervalPrice)}/mo.
      </Text>

      {isChoosePlan ? (
        <Text
          as="p"
          sx={{
            fontSize: [2, 2, 3],
            fontWeight: 'bold',
            lineHeight: 'body',
            pb: [3, 3, 4]
          }}
        >
          ${toMoney(currentSale.priceInCents)} {currentSale.recurringInterval}{' '}
          payment
        </Text>
      ) : (
        <Text
          as="p"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: [1, 1],
            color: 'grays.450',
            py: 4
          }}
        >
          Each service on their own would cost:
        </Text>
      )}

      {isChoosePlan ? (
        <Box
          as="ul"
          sx={{
            px: 0,
            py: [3, 3, 4],
            mb: 6,
            svg: {
              color: 'primary',
              width: '21px',
              height: '17px',
              mr: 2
            }
          }}
        >
          {checkList.map((item, index) => (
            <Flex
              key={index}
              sx={{
                alignItems: 'center',
                svg: {
                  color: item.disabled ? 'transparent' : 'primary'
                }
              }}
            >
              <DoneIcon />

              <Box
                as="li"
                sx={{
                  display: 'inline-block',
                  fontSize: [2, 2, 3],
                  lineHeight: 'button',
                  py: [3, 3, 4],
                  color: item.disabled ? 'muted' : 'blacks.500'
                }}
              >
                {item.text}
              </Box>
            </Flex>
          ))}
        </Box>
      ) : (
        <PriceTable priceList={priceList} total={sale.retailPriceInCents} />
      )}

      <AddToCartProvider>
        <SalePurchaseButton
          sale={currentSale}
          warrantyId={warrantyId as number}
          showQuantity={false}
          showPaypal={false}
          sx={{
            minWidth: 'auto',
            justifyContent: isChoosePlan ? 'flex-start' : 'center',
            mx: isChoosePlan ? 0 : 'auto',
            mb: 7
          }}
        />
      </AddToCartProvider>

      {currentSale.isRecurringTrial && (
        <Text
          sx={{
            display: 'inline-block',
            fontSize: [1, 1, 2],
            color: 'grays.600',
            my: 1
          }}
        >
          {disclaimer}
        </Text>
      )}
    </Box>
  )
}

export default ChooseRecurringPlan
