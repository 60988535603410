/* istanbul ignore file */
import Heading from '@atoms/UIHeading/Heading'
import NumberPicker, {
  NumberPickerType
} from '@atoms/NumberPicker/NumberPicker'
import { Box, Flex } from '@lib/UIComponents'

export type NumberPickersType = {
  selected: string
  title: string
  items: Array<NumberPickerType>
}

const styles = {
  container: {
    mb: 7,
    pb: 5,
    borderBottom: '1px solid',
    borderColor: 'grays.100'
  }
}

const NumberPickerOptions = ({ title, selected, items }: NumberPickersType) => {
  return (
    <Box sx={styles.container} data-testid="numberPicker">
      <Heading level={4} sx={{ mb: 4 }}>
        {title}
      </Heading>
      <Flex sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
        {items.map((item: NumberPickerType) => (
          <NumberPicker
            key={item.id}
            id={item.id}
            slug={item.slug}
            name={item.name}
            soldOut={item.soldOut}
            checked={selected === item.slug}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default NumberPickerOptions
