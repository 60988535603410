import { useState } from 'react'

import { TopStarIcon } from '@atoms/Icon/Icon'
import { initials } from '@utils/strings'
import { Box, Image, Text } from '@lib/UIComponents'

type AvatarProps = {
  size?: 'large' | 'medium' | 'small' | { width: number; height: number }
  src?: string
  alt: string
  highlight?: boolean
  onlyMobile?: boolean
  firstName: string
  lastName?: string
  fontSize?: Array<string>
}

const imageDefaultSizes = {
  small: { width: ['30px'], height: ['30px'] },
  medium: { width: ['60px'], height: ['60px'] },
  large: { width: ['79px', '94px'], height: ['79px', '94px'] }
}

const ImageBaseStyles = {
  image: {
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: 'circle'
  }
}

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  icon: (isMobile: boolean) => ({
    color: 'highlight',
    position: 'absolute',
    bottom: '-23%',
    right: '-23%',
    display: isMobile ? ['block', 'none'] : ['block']
  }),

  avatar: (fontSize: Array<string>) => ({
    borderRadius: 'circle',
    bg: 'info',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: 'primaries.700',
    fontSize,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  })
}

const removesPixel = (size: string): number => {
  return parseInt(size.replace('px', ''))
}

const Avatar = ({
  size = 'small',
  src,
  alt,
  highlight = false,
  onlyMobile = false,
  firstName,
  lastName,
  fontSize = ['12px', '12px', '23px']
}: AvatarProps) => {
  const [onError, setOnError] = useState(false)

  if (!firstName && !lastName && !src) return null

  const imageStyles = {
    ...ImageBaseStyles.image,
    ...(typeof size === 'object' ? size : imageDefaultSizes[size])
  }

  const iconRatio = 0.56

  const IconSize =
    typeof imageStyles.width === 'object'
      ? [
          removesPixel(imageStyles.width[0]) * iconRatio + 'px',
          removesPixel(
            imageStyles.width[1] ? imageStyles.width[1] : imageStyles.width[0]
          ) *
            iconRatio +
            'px'
        ]
      : [
          `${imageStyles.width * iconRatio}px`,
          `${imageStyles.width * iconRatio}px`
        ]

  return (
    <Box sx={styles.wrapper}>
      {src && !onError ? (
        <>
          <Image
            sx={imageStyles}
            onError={() => setOnError(true)}
            src={src}
            alt={alt}
          />
          {highlight && (
            <Box
              sx={{
                ...styles.icon(onlyMobile),
                fontSize: IconSize
              }}
            >
              <TopStarIcon />
            </Box>
          )}
        </>
      ) : (
        <Text
          sx={{
            ...styles.avatar(fontSize),
            ...(typeof size === 'object' ? size : imageDefaultSizes[size])
          }}
        >
          {initials({
            firstName,
            lastName: lastName || ''
          })}
        </Text>
      )}
    </Box>
  )
}

export default Avatar
