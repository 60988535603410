import { initializeAuthApollo } from '@lib/apollo'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import Seo from '@concepts/Seo/Seo'

import Layout from '@templates/Layout/Layout'
import SaleDetails from '@concepts/Sales/views/SaleDetails'
import { withPublisher } from '@middlewares/tenancy/publisher'
import {
  SaleDetailsProps,
  SaleDetailsType,
  SaleTypeEnum
} from '@concepts/Sales/types/sale'
import SaleDetailsRepository from '@concepts/Sales/repository/SaleDetailsRepository'
import useSaleMetaData from '@concepts/Sales/hooks/useSaleMetaData'
import SaleProvider from '@concepts/Sales/store/SaleProvider'
import AddToCartProvider from '@concepts/Sales/store/AddToCartProvider'
import Statuses from '@lib/http/status'

const shouldAddBottomPadding = (saleType: string) => {
  const saleTypesWithMenu = [
    SaleTypeEnum.Freebie,
    SaleTypeEnum.Recurring,
    SaleTypeEnum.Solo,
    SaleTypeEnum.SoloVariant
  ]

  return saleTypesWithMenu.includes(saleType as SaleTypeEnum)
}

const styles = {
  saleFooter: (type: string) => ({
    pb: shouldAddBottomPadding(type) ? ['50px', '50px', '50px', 0] : 0
  }),
  navigation: {
    '@media screen and (max-width: 1024px)': {
      'nav.inline-navigation': {
        display: 'none'
      }
    }
  }
}

const SalePage = ({
  sale
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element => {
  const { twitterMetaData, ogMetaData } = useSaleMetaData({
    title: sale.title,
    imageUrl: sale.imageCollection?.showcaseImage.url as string,
    metaDescription: sale.metaDescription,
    priceInCents: sale.priceInCents,
    calculatedDiscount: sale.calculatedDiscount
  })

  const noindex = sale.noindex || sale.isPrivate

  return (
    <Layout
      useContainer={false}
      footerSx={styles.saleFooter(sale.type)}
      sx={styles.navigation}
    >
      <Seo
        page={sale.name}
        customMetaDescription={sale.metaDescription || sale.title}
        ogMeta={ogMetaData}
        twitterMeta={twitterMetaData}
        customTitle={sale.customSalePageTitle}
        customCanonicalUrl={sale.canonicalUrl}
        {...(noindex ? { customMetaRobots: 'noindex,nofollow' } : {})}
      />

      <AddToCartProvider>
        <SaleProvider sale={sale}>
          <SaleDetails />
        </SaleProvider>
      </AddToCartProvider>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps<SaleDetailsProps> = async (
  context
) => {
  const { query } = context

  const apolloClient = initializeAuthApollo({}, context)

  const sale = await SaleDetailsRepository.find(
    query.slug as string,
    apolloClient
  )

  return withPublisher<SaleDetailsProps>(
    context,
    {
      errorCode: sale ? null : Statuses.NOT_FOUND,
      initialApolloState: apolloClient.cache.extract(),
      sale: sale as SaleDetailsType,
      featureFlags: [
        { name: 'paypal_pay_now_button', actor: `sale:${sale?.id}` },
        { name: 'paypal_pay_in_4_message', actor: 'publisher' },
        { name: 'ssr_use_sale_banner', actor: `sale:${sale?.id}` }
      ]
    },
    { fetchSSRUser: false }
  )
}

export default SalePage
