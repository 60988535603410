/* istanbul ignore file */

import Disclaimer from '@atoms/Disclaimer/Disclaimer'
import ReviewItem, { Review } from '@molecules/ReviewItem/ReviewItem'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

type ReviewTableProps = {
  reviews: Array<Review>
  sx?: UIStyles
}

const styles = {
  default: {
    color: 'blacks.500',
    pb: 7,
    borderColor: 'grays.100'
  },
  title: {
    color: 'grays.600',
    mt: 10
  }
}

const disclaimer =
  'All reviews are from verified purchasers collected after purchase.'

const ReviewTable = ({ reviews, sx = {} }: ReviewTableProps) => {
  return (
    <Box sx={{ ...styles.default, ...sx }}>
      <Disclaimer text={disclaimer} />

      {reviews.map((review, index) => {
        return <ReviewItem key={index} reviewData={review} />
      })}
    </Box>
  )
}

export default ReviewTable
