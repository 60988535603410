import { CheckMarkIcon } from '@atoms/Icon/Icon'
import { Course } from 'src/generated/graphql'
import { isEmpty, mergeDeepRight } from 'ramda'
import { pluralize } from '@utils/strings'
import { Box, Flex, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type CheckListProps = {
  course: Course
  sx?: object
}

const styles = {
  list: {
    p: 0,
    listStyleType: 'none',
    flexWrap: 'wrap',
    li: {
      '&:nth-of-type(1), &:nth-of-type(2)': {
        mt: [5, 5, 5, 0, 0]
      }
    }
  },
  item: {
    display: 'flex',
    flex: ['1 0 100%', '1 0 100%', '1 0 100%', '1 0 50%', '1 0 50%'],
    alignItems: 'center',
    pr: 4,
    mt: [5, 5, 5, '40px', '40px'],
    svg: {
      color: 'primary'
    }
  }
}

const Item = ({ text }: { text: string }) => (
  <Box as="li" sx={styles.item}>
    <CheckMarkIcon />
    <Text as="span" sx={{ ml: 4, flex: 1 }}>
      {text}
    </Text>
  </Box>
)

const Checklist = ({ course, sx = {} }: CheckListProps) => {
  if (!course || isEmpty(course)) return null

  const {
    certification,
    skillLevel,
    duration,
    numberOfLessons,
    accessTimeFrame
  } = course

  const lecturePluralize = pluralize(
    numberOfLessons as number,
    'lecture',
    'lectures'
  )
  const hourPluralize = pluralize(duration as number, 'hour', 'hours')

  return (
    <Flex
      as="ul"
      sx={mergeDeepRight(styles.list, sx) as UIStyles}
      data-testid="checklist"
    >
      {certification && <Item text="Certification included" />}
      {skillLevel && <Item text={`Experience level required: ${skillLevel}`} />}
      {duration && numberOfLessons ? (
        <Item
          text={`Access ${numberOfLessons} ${lecturePluralize} & ${duration} ${hourPluralize} of content 24/7`}
        />
      ) : (
        <></>
      )}
      {accessTimeFrame && (
        <Item
          text={`Length of time users can access this course: ${accessTimeFrame}`}
        />
      )}
    </Flex>
  )
}

export default Checklist
