import Currency from '@atoms/UICurrency/Currency'
import { ProductsWithLocked } from '@concepts/Sales/views/sale-types/utils'
import LockIcon from '@icons/lock.svg'
import { Box, Flex, Image, Text } from '@lib/UIComponents'
import useScrollToElement from '@molecules/SaleTabs/hooks/useScrollToElement'
import ShowMore from '@molecules/ShowMore/ShowMore'

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(3, 1fr)'
    ],
    gap: 4
  },
  cardContainer: (locked: boolean) => ({
    position: 'relative',
    justifyContent: locked ? 'space-between' : 'flex-start',
    maxWidth: ['335px', '335px', 'none'],
    borderRadius: 'default',
    border: '1px solid',
    borderColor: 'grays.100',
    pr: [4, 0],
    cursor: 'pointer',
    'div:not(.lock-icon)': {
      opacity: locked ? 0.5 : 1
    }
  }),
  textContainer: {
    py: [2, 4],
    mr: [0, 6],
    pr: [5, 0]
  },
  image: {
    height: ['100%', '102px'],
    m: ['0 15px 0 0', '15px'],
    minWidth: 'auto',
    maxWidth: ['13px', '114px'],
    objectFit: 'cover',
    borderRadius: [0, 'default']
  },
  value: {
    color: 'grays.600',
    fontSize: 'sm'
  },
  text: {
    display: 'block',
    mt: '2',
    fontSize: 'sm'
  },
  icon: {
    display: ['flex', 'block'],
    position: ['static', 'absolute'],
    top: [null, '51px'],
    left: [null, '59px'],
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: [2, 5]
  }
}

type CardGridProps = {
  totalValue: number
  products: ProductsWithLocked[]
}

const CardGrid = ({ totalValue, products }: CardGridProps) => {
  const { scrollToElementId } = useScrollToElement()

  return (
    <Box>
      <Text
        as="h2"
        sx={{
          mb: [6, 6, 7],
          fontSize: [3, 3, 6]
        }}
      >
        What's Included In This Bundle (
        <Currency
          sx={{
            fontSize: [3, 3, 6],
            fontWeight: 'bold'
          }}
        >
          {totalValue}
        </Currency>
        ) Value
      </Text>
      <ShowMore mobileOnly maxHeight={400}>
        <Flex sx={{ justifyContent: 'center' }}>
          <Box sx={styles.container}>
            {products.map((product) => {
              return (
                <Flex
                  sx={styles.cardContainer(product.locked)}
                  key={product.name}
                  onClick={() => scrollToElementId('product-details')}
                >
                  <Flex>
                    <Image
                      sx={styles.image}
                      alt={product.name}
                      src={product.iconImage.url as string}
                    />
                    <Box sx={styles.textContainer}>
                      <Currency sx={styles.value}>
                        {product.retailPriceInCents}
                      </Currency>{' '}
                      <Text sx={styles.value}>Value</Text>
                      <Text sx={styles.text}>{product.name}</Text>
                    </Box>
                  </Flex>
                  {product.locked && (
                    <Box sx={styles.icon} className="lock-icon">
                      <LockIcon />
                    </Box>
                  )}
                </Flex>
              )
            })}
          </Box>
        </Flex>
      </ShowMore>
    </Box>
  )
}

export default CardGrid
