import Nyop from './NYOP'
import Solo from './Solo'
import Bundle from './Bundle'
import Course from './Course'
import Freebie from './Freebie'
import Recurring from './Recurring'
import PwywProvider from './store/PwywProvider'

import Text from '@atoms/Text/Text'

import { SaleDetailsProps, SaleTypeEnum } from '@concepts/Sales/types/sale'

const SaleType: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  switch (sale.type) {
    case SaleTypeEnum.Freebie:
      return <Freebie sale={sale} />

    case SaleTypeEnum.Recurring:
      return <Recurring sale={sale} />

    case SaleTypeEnum.Solo:
    case SaleTypeEnum.SoloVariant:
      return sale.useCourseLayout && sale.firstProduct?.course ? (
        <Course sale={sale} />
      ) : (
        <Solo sale={sale} />
      )

    case SaleTypeEnum.NYOPFull:
    case SaleTypeEnum.NYOPMini:
      return (
        <PwywProvider>
          <Nyop sale={sale} />
        </PwywProvider>
      )

    case SaleTypeEnum.Bundle:
    case SaleTypeEnum.BigBundle:
      return <Bundle sale={sale} />

    default:
      return null
  }
}

const SaleTypeWrapper: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => (
  <>
    <Text data-testid="saleType" hidden value={sale.type} />
    <SaleType data-testid="salePage" sale={sale} />
  </>
)

export default SaleTypeWrapper
