import { franc } from 'franc-min'

import ILangDetectionAdapter from '../interface'

import { iso6393To1 } from './languageCodes'

const francAdapter: ILangDetectionAdapter = {
  detect: (text) => {
    return iso6393To1[franc(text)] || 'en'
  }
}

export default francAdapter
