/* eslint-disable @typescript-eslint/no-explicit-any */
import { Element } from 'html-react-parser'

export const separateTitleFromTime = (
  title: string
): { title: string; time: string } => {
  const newTitle = title.split(' ')
  const lastString = newTitle.slice(-1)[0]

  const timeRegex = /^(\(?[0-9]?[0-9]):[0-5]\d\)?$/
  const time = timeRegex.test(lastString)
    ? lastString.replace(/\(|\)/g, '')
    : ''

  if (time) {
    newTitle.pop()
  }

  if (newTitle.slice(-1)[0] === '-') {
    newTitle.pop()
  }

  return {
    title: newTitle.join(' '),
    time
  }
}

const recursiveGetItemData = (item: any): any => {
  return item.type === 'text'
    ? item.data
    : recursiveGetItemData(item.children[0])
}

export const getData = (content: Element[]): Array<string> => {
  if (content.length <= 0) return []

  return content[0].children
    .filter((item: { type: string }) => item.type === 'tag')
    .map((item: any) => {
      if (item.children.length > 1) {
        return item.children.map((i: any) => recursiveGetItemData(i)).join(' ')
      }

      return item.children[0].data
        ? item.children[0].data
        : recursiveGetItemData(item.children[0])
    })
}
