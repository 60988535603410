/* istanbul ignore file */
import Text from '@atoms/Text/Text'
import Link from '@atoms/Link/Link'
import { useAddToCartContext } from '@concepts/Sales/store/addToCartContext'

export type NumberPickerType = {
  id: string
  slug: string
  name: string
  soldOut: boolean
  checked?: boolean
}

const styles = {
  container: (checked: boolean, length: number) => ({
    backgroundColor: checked ? 'blacks.0' : 'background',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'circle',
    border: '1px solid',
    borderColor: 'grays.2',
    color: 'primary',
    minWidth: ['40px', '40px', '44px'],
    minHeight: ['40px', '40px', '44px'],
    px: length > 2 ? 4 : 1,
    mb: 2,
    mr: 2,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 250ms ease-in-out',
    '& > span': {
      color: checked ? 'background' : 'blacks.0',
      transition: 'color 250ms ease-in-out',
      fontWeight: 'bold',
      fontFamily: 'body',
      fontSize: length > 2 ? [1, 1, 2] : [3, 3, 4]
    },
    '&:hover': {
      backgroundColor: 'black',
      '& > span': {
        color: 'background'
      }
    }
  }),

  soldOut: {
    whiteSpace: 'nowrap',
    opacity: 0.4,
    color: 'blacks.0',
    pointerEvents: 'none',
    userSelect: 'none',
    '.soldOutText': {
      color: 'grays.6',
      ml: 2,
      minWidth: ['70px', '70px']
    }
  },

  productName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

const NumberPicker: React.FC<React.PropsWithChildren<NumberPickerType>> = ({
  slug,
  name,
  soldOut,
  checked = false
}) => {
  const { setIsSubmitting } = useAddToCartContext()

  return (
    <Link
      onClick={() => {
        setIsSubmitting(true)
      }}
      sx={{
        ...styles.container(checked, name.length),
        ...(soldOut && styles.soldOut)
      }}
      tabIndex={0}
      to={slug}
    >
      <Text sx={styles.productName}>{name}</Text>

      {soldOut && <Text className="soldOutText">SOLD OUT</Text>}
    </Link>
  )
}

export default NumberPicker
