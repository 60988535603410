import { Box, Flex } from 'rebass'

import Breadcrumb from '@atoms/Breadcrumb/Breadcrumb'
import Container from '@atoms/Container/Container'
import useFreebie from '@concepts/Sales/hooks/useFreebie'
import { SaleDetailsProps } from '@concepts/Sales/types/sale'
import SaleAuthForm from '@concepts/Sales/views/SaleAuthForm/SaleAuthForm'
import CollapsibleCardList from '@molecules/CollapsibleCardList/CollapsibleCardList'
import SaleGallery from '@molecules/SaleGallery/SaleGallery'
import SaleSummary from '@molecules/SaleSummary/SaleSummary'
import FullLoader from '@atoms/Loader/FullLoader'
import Link from '@atoms/Link/Link'
import Button from '@atoms/Button/Button'

const styles = {
  container: {
    pb: [2, 7, 8, 10, '80px'],
    pt: [4, 6, 8]
  },
  grid: {
    display: 'grid',
    gridColumnGap: [0, 0, 0, 10, '80px'],
    gridTemplateColumns: ['1fr', '1fr', '1fr', '1.2fr .8fr', '1.2fr .8fr']
  },
  galleryWrapper: {
    gridRowStart: 1,
    gridRowEnd: 7
  },
  brand: {
    mt: -2
  }
}

const Freebie: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  const isBundle = sale.products.length > 1

  const freebieText =
    'Login to share this on social media and get access to this freebie.'

  const {
    loading,
    isButtonDisabled,
    setIsButtonDisabled,
    prepareFreebieForCheckout
  } = useFreebie({
    sale
  })

  return (
    <Container withDefaultPadding sx={styles.container}>
      <Breadcrumb breadcrumb={sale.breadcrumb} sx={{ ml: [0, 3] }} />
      <FullLoader show={loading} message="Processing purchase..." />

      <Box sx={styles.grid}>
        <Box sx={styles.galleryWrapper}>
          <SaleGallery mediaList={sale.medias} saleName={sale.name} />
        </Box>

        <SaleSummary sale={sale} />

        {sale.isActive ? (
          <SaleAuthForm
            title={sale.title}
            text={freebieText}
            btnOnClick={prepareFreebieForCheckout}
            disableButton={isButtonDisabled}
            beforeShareClick={() => setIsButtonDisabled(false)}
          />
        ) : (
          <Flex sx={{ justifyContent: 'center' }}>
            <Button
              block
              sx={{
                maxWidth: '280px'
              }}
              as={Link}
              variant="dark"
              to="/free"
            >
              See More Freebies
            </Button>
          </Flex>
        )}
      </Box>

      {isBundle && (
        <CollapsibleCardList
          products={sale.products}
          sx={{ mt: sale.isActive ? '' : 9 }}
          terms={sale.terms}
          countries={sale.shippingMessage}
          title="What's Included"
        />
      )}
    </Container>
  )
}

export default Freebie
