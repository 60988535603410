import Head from 'next/head'

import { toHttpsUrl } from '@utils/url'
import { formatCents } from '@utils/strings'
import { Publisher } from 'src/generated/graphql'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { isProprietary } from '@concepts/Publisher/namedPublisher'
import { isVideo } from '@molecules/SaleGallery/Utils/DisplayThumbnails'
import { formatDateYYYYMMDD } from '@utils/dates'

const toSchema = (publisher: Publisher, sale: SaleDetailsType) => {
  const url = `${toHttpsUrl(publisher.hostname as string)}sales/${sale.slug}`
  const hasReviews = sale.saleReviews.totalCount > 0

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: sale.name,
    image: sale.medias.filter(({ url }) => !isVideo(url)).map(({ url }) => url),
    description: sale.metaDescription,
    brand: sale.brand?.name || '',
    offers: {
      '@type': 'Offer',
      url,
      priceCurrency: 'USD',
      price: formatCents(sale.priceInCents).replace('$', ''),
      priceValidUntil: formatDateYYYYMMDD(sale.priceValidUntil),
      availability: sale.soldOut
        ? 'https://schema.org/OutOfStock'
        : sale.expired
          ? 'https://schema.org/Discontinued'
          : 'https://schema.org/InStock'
    },
    aggregateRating: hasReviews
      ? {
          '@type': 'AggregateRating',
          ratingValue: sale.saleReviews.averageScore,
          reviewCount: sale.saleReviews.totalCount,
          bestRating: '5',
          worstRating: '1'
        }
      : null,
    review: hasReviews
      ? sale.saleReviews.reviews.map((review) => ({
          '@type': 'Review',
          datePublished: review.createdAt,
          reviewBody: review.comment,
          author: {
            name: `${review.user.firstName} ${review.user.lastName}`,
            '@type': 'Person'
          },
          reviewRating: {
            '@type': 'Rating',
            bestRating: '5',
            ratingValue: review.rating,
            worstRating: '1'
          }
        }))
      : null
  }
}

const SalePageSchema: React.FC<
  React.PropsWithChildren<{
    publisher: Publisher
    sale: SaleDetailsType
  }>
> = ({ publisher, sale }) => {
  if (!isProprietary(publisher.code as string)) return null

  const schema = toSchema(publisher, sale)

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: `${JSON.stringify(schema)}` }}
      />
    </Head>
  )
}

export default SalePageSchema
