import { format } from 'date-fns'
import { useRouter } from 'next/router'
import { mergeDeepRight } from 'ramda'
import { useCountdownTimer } from 'use-countdown-timer'

import AddedToCartCounter from '@atoms/AddedToCartCounter/AddedToCartCounter'
import Button from '@atoms/UIButton/Button'
import { SaleDetailsType } from '@concepts/Sales/types/sale'
import { usePwywContext } from '@concepts/Sales/views/sale-types/store/pwywContext'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import useAddSaleToCart from '@molecules/AddSaleToCart/hooks/useAddSaleToCart'
import SalePurchaseButton from '@molecules/SalePurchaseButton/SalePurchaseButton'
import { formatCents } from '@utils/strings'
import PriceOfferItem from './PriceOfferItem'

type PriceOfferProps = {
  sale: SaleDetailsType
  addedToCartCount?: number
  placedOnImageBackground?: boolean
  sx?: UIStyles
}

const styles = {
  warning: {
    color: 'reds.700',
    display: 'block',
    fontSize: 'sm'
  }
}

const MINUTES = 15
export const NYOP_COUNTDOWN_TIMER = 1000 * 60 * MINUTES

const PriceOffer = ({
  sale,
  addedToCartCount,
  placedOnImageBackground = false,
  sx = {}
}: PriceOfferProps) => {
  const router = useRouter()
  const { priceOffer, setPriceOffer } = usePwywContext()
  const { priceToBeatAverageCents, nyopMinimumToMiniCents } = sale
  const { warrantyId, bidPrice, setBidPrice } = useAddSaleToCart({
    sale
  })

  const { countdown } = useCountdownTimer({
    timer: NYOP_COUNTDOWN_TIMER,
    autostart: true,
    resetOnExpire: false
  })

  const disableCartButton = bidPrice < nyopMinimumToMiniCents

  return (
    <Box
      sx={mergeDeepRight(
        {
          width: ['100%', '100%', '100%', '325px']
        },
        sx as object
      )}
    >
      <Box
        sx={{
          py: 3,
          textAlign: 'center',
          backgroundColor: 'danger2Alpha',
          color: 'reds.700',
          borderTopLeftRadius: 'default',
          borderTopRightRadius: 'default',
          border: '1px solid',
          borderColor: 'grays.50',
          borderBottom: 0
        }}
      >
        {countdown ? (
          <Text
            sx={{
              fontVariantNumeric: 'tabular-nums'
            }}
          >
            Price locked in for: {format(countdown, 'mm:ss')}
          </Text>
        ) : (
          <Text>Price expired. Refresh to get new price.</Text>
        )}
      </Box>
      <Box sx={{ mb: 4 }}>
        <PriceOfferItem
          id="averagePrice"
          title="Beat the average price"
          onClick={setPriceOffer}
          price={priceToBeatAverageCents}
          checked={priceOffer === 'averagePrice'}
          setBidPrice={setBidPrice}
          placedOnImageBackground={placedOnImageBackground}
        />

        <PriceOfferItem
          hasInput
          id="payWhatYouWantPrice"
          title="Pay what you want"
          onClick={setPriceOffer}
          checked={priceOffer === 'payWhatYouWantPrice'}
          setBidPrice={setBidPrice}
          placedOnImageBackground={placedOnImageBackground}
        />
      </Box>
      <Box sx={{ mb: 4 }}>
        {nyopMinimumToMiniCents > bidPrice && (
          <Text sx={styles.warning}>
            The minimum price for this mini bundle is{' '}
            {formatCents(nyopMinimumToMiniCents)}.
          </Text>
        )}

        {priceToBeatAverageCents > bidPrice && (
          <Text sx={styles.warning}>
            You will not unlock the full bundle unless you beat{' '}
            {formatCents(priceToBeatAverageCents)}.
          </Text>
        )}
      </Box>
      {countdown ? (
        <SalePurchaseButton
          sale={sale}
          warrantyId={warrantyId as number}
          showQuantity={false}
          bidPrice={bidPrice}
          placedOnImageBackground={placedOnImageBackground}
          paypalSx={{
            mb: 3,
            maxWidth: 'none'
          }}
          sx={{
            minWidth: 'auto',
            justifyContent: [
              'flex-start',
              'flex-start',
              'flex-end',
              'flex-end'
            ],
            button: {
              maxWidth: ['none', 'none', '285px', '285px'],
              opacity: disableCartButton ? 0.5 : 1.0,
              cursor: disableCartButton ? 'not-allowed' : 'pointer'
            }
          }}
        />
      ) : (
        <Box sx={{ textAlign: 'right' }}>
          <Button
            onClick={() => router.reload()}
            sx={{
              width: '100%',
              maxWidth: ['none', 'none', '285px', '285px'],
              justifyContent: 'center',
              mb: 4
            }}
            variant="dark"
          >
            Refresh Page
          </Button>
        </Box>
      )}
      {addedToCartCount && <AddedToCartCounter counter={addedToCartCount} />}
    </Box>
  )
}

export default PriceOffer
