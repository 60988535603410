import { useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { mergeDeepRight } from 'ramda'

import Card from '@atoms/UICard/Card'
import Currency from '@atoms/UICurrency/Currency'
import {
  AuthorIcon,
  CertificateIcon,
  ChevronDownIcon,
  ModulesIcon
} from '@atoms/Icon/Icon'

import { useSaleContext } from '@concepts/Sales/store/saleContext'
import { title as courseTitle } from '@organisms/CourseOverview/CourseOverview'
import { Box, Flex, Image, Text } from '@lib/UIComponents'

type CollapsibleCardProps = {
  id: string
  title: string
  author: string
  duration: number
  numberOfLessons: number
  license: string
  price: number
  image: string
  sx?: object
  isMissingDetails?: boolean
  children?: React.ReactNode
}

const styles = {
  card: {
    overflow: 'hidden',
    p: 0
  },
  grid: {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: [
      '20px 1fr 50px',
      '20px 1fr 50px',
      'minmax(120px, 263px) 1fr 50px'
    ],
    gridGap: [2, 2, 12],
    width: '100%'
  },
  headerContent: {
    pt: [3, 3, 4],
    pb: [2, 2, 3],
    px: 2,
    justifyContent: 'center',
    flexDirection: 'column',
    maxHeight: ['170px', '170px', 'none', '167px']
  },
  textValue: {
    fontFamily: 'body',
    fontStyle: 'normal',
    fontSize: [1, 1, 2],
    lineHeight: '150%',
    transition: '200ms ease-in-out',
    transitionProperty: 'color, font-weight'
  },
  textTitle: {
    m: 0,
    fontFamily: 'body',
    color: 'blacks.1',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: [3, 3, 5],
    lineHeight: ['118%', '118%', '131%']
  },
  textDetail: {
    fontFamily: 'body',
    fontStyle: 'normal',
    fontWeight: 'body',
    fontSize: [1, 1, 2],
    transition: 'color 250ms ease-in-out'
  },
  details: {
    flexWrap: 'wrap',
    gridColumnGap: [2, 2, 5]
  },
  detailIcon: {
    fontSize: [2, 2, 4],
    pr: 2,
    verticalAlign: 'bottom',
    transition: 'color 250ms ease-in-out'
  },
  image: {
    height: '100%',
    maxHeight: ['170px', '170px', 'none', '167px'],
    display: 'flex',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  icon: {
    fontSize: [2, 2, 4],
    alignItems: 'center',
    justifyContent: ['center', 'center', 'flex-start'],
    maxHeight: ['170px', '170px', 'none', '167px']
  }
}

const CollapsibleCard = ({
  children,
  id,
  title,
  author,
  duration,
  numberOfLessons,
  license,
  price,
  image,
  sx = {},
  isMissingDetails = false
}: CollapsibleCardProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { findProductDetails } = useSaleContext()

  const { transform, ...props } = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    maxHeight: isOpen ? '9999px' : '0',
    config: { mass: 1, tension: 600, friction: 50 }
  })

  const handleClick = () => {
    setIsOpen(!isOpen)

    if (isMissingDetails) {
      findProductDetails(id)
    }
  }

  return (
    <Card sx={mergeDeepRight(styles.card, sx)}>
      <Box
        sx={{
          ...styles.grid,
          borderBottom: isOpen ? '1px solid' : 'none',
          borderColor: 'grays.100'
        }}
        data-testid="expand-card"
        aria-label={`${isOpen ? 'Close' : 'Open'} collapsible`}
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={handleClick}
      >
        <Image sx={styles.image} src={image} alt={title} />

        <Flex sx={styles.headerContent}>
          <Text sx={styles.textValue}>
            <Currency>{price}</Currency> Value
          </Text>

          <Text as="h3" sx={styles.textTitle}>
            {title}
          </Text>

          {author && numberOfLessons && (
            <Flex sx={{ ...styles.details, pt: [3, 3, 2], pb: [1, 1, 2] }}>
              <Flex>
                <Box
                  sx={{
                    ...styles.detailIcon,
                    color: 'grays.700'
                  }}
                >
                  <AuthorIcon />
                </Box>

                <Text
                  sx={{
                    ...styles.textDetail,
                    color: 'grays.700',
                    pr: [2, 2, 3]
                  }}
                >
                  {author}
                </Text>
              </Flex>

              <Flex>
                <Box
                  sx={{
                    ...styles.detailIcon,
                    color: 'grays.700'
                  }}
                >
                  <ModulesIcon />
                </Box>

                <Text
                  sx={{
                    ...styles.textDetail,
                    color: 'grays.700'
                  }}
                >
                  {courseTitle(numberOfLessons, duration)}
                </Text>
              </Flex>

              <Flex sx={{ mt: [1, 1, 0] }}>
                <Box
                  sx={{
                    ...styles.detailIcon,
                    color: 'grays.700'
                  }}
                >
                  <CertificateIcon />
                </Box>

                <Text
                  sx={{
                    ...styles.textDetail,
                    color: 'grays.700'
                  }}
                >
                  {license}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex sx={styles.icon}>
          <animated.span
            style={{ transform, cursor: 'pointer', lineHeight: 0 }}
          >
            <ChevronDownIcon />
          </animated.span>
        </Flex>
      </Box>
      <Box>
        <animated.div
          style={{
            overflow: 'hidden',
            ...props
          }}
          ref={ref}
        >
          {children}
        </animated.div>
      </Box>
    </Card>
  )
}

export default CollapsibleCard
