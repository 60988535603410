import Link, { Path, LinkProps } from '@atoms/Link/Link'

import {
  Heading as RebassHeading,
  HeadingProps as RebassHeadingProps
} from 'rebass'

type Level = 1 | 2 | 3 | 4 | 5 | 6

type HeadingProps = {
  level?: Level
  link?: Omit<LinkProps, 'to'> & {
    to?: Path
  }
  css?: string
} & RebassHeadingProps

const levels = {
  1: { fontSize: 6 },
  2: { fontSize: 5 },
  3: { fontSize: 4 },
  4: { fontSize: 3 },
  5: { fontSize: 2 },
  6: { fontSize: 1 }
}

const Heading = ({
  as = 'h2',
  level = 2,
  variant = 'heading',
  children,
  link,
  ...props
}: HeadingProps) => {
  if (link?.to) {
    const { to, ...linkProps } = link

    children = (
      <Link to={to as Path} {...linkProps}>
        {children}
      </Link>
    )
  }

  return (
    <RebassHeading as={as} variant={variant} {...levels[level]} {...props}>
      {children}
    </RebassHeading>
  )
}

export default Heading
