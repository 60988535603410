/* istanbul ignore file */
import { MouseEventHandler } from 'react'
import { Box, Flex } from 'rebass'

import Button from '@atoms/Button/Button'
import Container from '@atoms/Container/Container'
import SingleLineSeparator from '@atoms/Separator/SingleLineSeparator'
import ReviewTable from '@molecules/ReviewTable/ReviewTable'
import RatingsAverage from '@molecules/Ratings/RatingsAverage'

import useSaleReviews from '../hooks/useSaleReviews'
import { Review } from '@molecules/ReviewItem/ReviewItem'
import { PageInfo } from 'src/generated/graphql'

type Props = {
  saleId: number
  averageRating: number
  reviewsCount: number
  initialReviews: Review[]
  initialPageInfo?: PageInfo
  sx?: object
}

const styles = {
  reviews: {
    color: 'primary',
    borderBottom: '1px solid',
    borderColor: 'grays.2',
    fontSize: 1,
    '&:last-child': {
      borderBottom: 0
    }
  },
  wrapper: {
    maxWidth: '1078px',
    margin: '0 auto'
  },
  button: {
    mt: 4,
    mb: [8, 8, 8],
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  btnText: {
    minWidth: 194,
    justifyContent: 'center',
    my: 0,
    mx: 5
  },
  rate: {
    mb: 7,
    '& > div': {
      width: 'max-content',
      minWidth: 'max-content'
    }
  }
}

export const SALE_REVIEWS_PER_PAGE = 6

const SaleReviews: React.FC<React.PropsWithChildren<Props>> = ({
  saleId,
  averageRating,
  reviewsCount,
  initialReviews,
  initialPageInfo,
  sx = {}
}) => {
  const { newReviews, pageInfo, getReviews, loading } = useSaleReviews({
    saleId,
    initialPageInfo: initialPageInfo as PageInfo
  })
  const reviews = [...initialReviews, ...newReviews]

  return (
    <>
      <Container id="reviews" withDefaultPadding sx={{ pt: 8 }}>
        <Box sx={{ ...styles.reviews, ...sx }} data-testid="saleReviews">
          <Box sx={styles.wrapper}>
            <Flex sx={styles.rate}>
              <RatingsAverage average={averageRating} total={reviewsCount} />
            </Flex>

            <ReviewTable reviews={reviews} />

            {pageInfo?.hasNextPage && (
              <Box sx={styles.button}>
                <SingleLineSeparator />

                <Button
                  variant="outlineDark"
                  sx={styles.btnText}
                  onClick={getReviews as MouseEventHandler}
                  loading={loading}
                >
                  Show More
                </Button>

                <SingleLineSeparator />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default SaleReviews
