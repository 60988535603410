import { mergeDeepRight } from 'ramda'
import { useState } from 'react'

import ExpandableList from '@atoms/ExpandableList/ExpandableList'
import { Box, Text } from '@lib/UIComponents'

export enum CourseOutlineStyle {
  Bundle = 'Bundle',
  Course = 'Course'
}

export type CourseOutlineProps = {
  title: string
  items: string
  maxHeight?: Array<string | number>
  variation?: CourseOutlineStyle
}

const getStyles = (variation: CourseOutlineStyle) => {
  const bundleStyles = {
    wrapper: {
      border: 'none'
    },
    scroll: {
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: [0, 0, 0, '7px', '7px']
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'grays.140',
        borderRadius: '14px'
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, .5)'
      }
    },
    curriculum: {
      fontWeight: 'bold',
      fontSize: ['12px', '12px', '12px', 1, 1],
      mb: ['18px', '18px', '18px', 0, 0],
      pt: 2,
      px: [0, 0, 0, 6, 6],
      textTransform: 'uppercase',
      letterSpacing: '0.135em'
    },
    heading: {
      fontSize: ['md', 'md', '2xl'],
      fontWeight: 'bold',
      px: [0, 0, 0, 6],
      mb: [0, 0, 0, 4]
    }
  }

  const courseStyles = {
    wrapper: {
      border: '1px solid',
      borderColor: 'grays.100',
      borderRadius: 'default',
      pt: [4, 4, 4, 6],
      overflow: 'hidden',
      backgroundColor: 'white'
    },
    heading: {
      mb: 2,
      px: [4, 4, 4, 6]
    }
  }

  switch (variation) {
    case CourseOutlineStyle.Course:
      return mergeDeepRight(bundleStyles, courseStyles)

    default:
      return bundleStyles
  }
}

const CourseOutline = ({
  title,
  items,
  maxHeight = ['auto'],
  variation = CourseOutlineStyle.Bundle
}: CourseOutlineProps) => {
  const styles = getStyles(variation)
  const [activeItem, setActiveItem] = useState<number | null>(null)

  if (!items) return null

  /* istanbul ignore next */
  const handleToggle = (id: number | null) => {
    setActiveItem(id)
  }

  const titleComponent = (
    <Text as="h3" sx={styles.heading} data-testid="titleCourseOutline">
      {title}
    </Text>
  )

  return (
    <Box sx={styles.wrapper} tabIndex={0} data-testid="courseOutline">
      {variation === CourseOutlineStyle.Bundle && (
        <Text as="h2" sx={styles.curriculum}>
          Course Curriculum
        </Text>
      )}
      {title && titleComponent}
      <Box sx={{ ...styles.scroll, maxHeight }}>
        <ExpandableList
          htmlText={items}
          handleToggle={handleToggle}
          activeItem={activeItem}
          variation={variation}
        />
      </Box>
    </Box>
  )
}

export default CourseOutline
