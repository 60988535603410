import { Box } from '@lib/UIComponents'

import Container from '@atoms/UIContainer/Container'
import LeaderboardChampion from '@molecules/LeaderboardChampion/LeaderboardChampion'
import LeaderboardTable from '@molecules/LeaderboardTable/LeaderboardTable'
import { LeaderboardType } from '@concepts/Sales/types/sale'

interface LeaderboardRankProps {
  users: Array<LeaderboardType>
}

const LeaderboardRank = ({ users }: LeaderboardRankProps) => {
  const champion = users[0]

  return (
    <>
      <Box sx={{ backgroundColor: 'grays.40', pb: '120px' }}>
        <Container withDefaultPadding>
          {champion && (
            <LeaderboardChampion
              rank={champion.rank}
              avatarSrc={champion.avatarSrc}
              name={champion.name}
              paidPriceCents={champion.paidPriceCents}
            />
          )}
        </Container>
      </Box>
      <Container sx={{ mt: ['-120px', '-120px', '-200px'] }} withDefaultPadding>
        <LeaderboardTable startWithSecond users={users} />
      </Container>
    </>
  )
}

export default LeaderboardRank
