import { isEmpty } from 'ramda'

import { Brand as BrandType } from 'src/generated/graphql'
import { UIStyles } from '@lib/UIComponents/types'
import { Text } from '@lib/UIComponents'
import Link from '@atoms/UILink/Link'

type BrandProps = {
  brand: BrandType | null
  sx?: UIStyles
}

const Brand = ({ brand, sx }: BrandProps) => {
  if (isEmpty(brand) || !brand) return null

  return (
    <Text
      sx={{
        ...sx,
        flex: '100%',
        fontSize: 'sm',
        mb: 5
      }}
    >
      by{' '}
      <Link
        to={`/brands/${brand.slug}`}
        target="_blank"
        sx={{
          color: 'primary',
          textTransform: 'capitalize'
        }}
      >
        {brand.name}
      </Link>
    </Text>
  )
}

export default Brand
