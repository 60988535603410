import { animated } from 'react-spring'
import { mergeDeepRight } from 'ramda'

import Button from '@atoms/UIButton/Button'
import SingleLineSeparator from '@atoms/Separator/SingleLineSeparator'
import { useShowMore } from './hooks/useShowMore'
import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  children: React.ReactNode
  maxHeight?: number
  mobileOnly?: boolean
  scrollOnClose?: boolean
  sx?: UIStyles
}

const gradient =
  'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 77%,rgba(255,255,255,1) 96%)'

const styles = {
  buttonWrapper: (mobileOnly: boolean) => ({
    justifyContent: 'center',
    alignItems: 'center',
    display: ['flex', 'flex', mobileOnly ? 'none' : 'flex'],
    width: '100%',
    pt: 2,
    position: 'relative'
  }),
  overlay: {
    position: 'absolute',
    bottom: '85%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    paddingTop: '150px',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    background: gradient,
    pointerEvents: 'none'
  },
  button: {
    minWidth: '194px',
    justifyContent: 'center',
    my: 0,
    mx: 5,
    transition: '250ms ease',
    transitionProperty: 'background-color, color'
  }
}

const ShowMore = ({
  children,
  maxHeight = 250,
  mobileOnly = false,
  scrollOnClose = false,
  sx = {}
}: Props) => {
  const { setAnimationRef, props, opacity, toggleButton, isOpen } = useShowMore(
    {
      maxHeight,
      mobileOnly,
      scrollOnClose
    }
  )

  return (
    <Box>
      <animated.div
        ref={setAnimationRef}
        data-testid="show-more-box"
        style={{ overflow: 'hidden', ...props }}
      >
        {children}
      </animated.div>

      <Box sx={mergeDeepRight(styles.buttonWrapper(mobileOnly), sx as object)}>
        <animated.div style={{ opacity }}>
          <Box sx={styles.overlay} />
        </animated.div>

        <SingleLineSeparator />

        <Button
          sx={{
            ...styles.button,
            backgroundColor: 'white'
          }}
          variant="outlineDark"
          onClick={toggleButton}
        >
          {isOpen ? 'Show Less' : 'Show More'}
        </Button>

        <SingleLineSeparator />
      </Box>
    </Box>
  )
}

export default ShowMore
