import { createContext, useContext } from 'react'
import { SaleDetailsType } from '../types/sale'

type SaleContextValues = {
  sale: SaleDetailsType
  findProductDetails: (id: string) => void
}

export const SaleContext = createContext<SaleContextValues | null>(null)

export const useSaleContext = (): SaleContextValues => {
  const saleContext = useContext(SaleContext)

  if (!saleContext) {
    throw new Error(
      'useSaleContext was called without being nested in SaleProvider'
    )
  }

  return saleContext
}
