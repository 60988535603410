import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { F } from 'ramda'

export type PriceOfferType = 'averagePrice' | 'payWhatYouWantPrice'

type PwywContextType = {
  pwywPrice: number
  setPwywPrice: Dispatch<SetStateAction<number>>
  priceOffer: PriceOfferType
  setPriceOffer: Dispatch<SetStateAction<PriceOfferType>>
}

export const PwywContext = createContext<PwywContextType>({
  pwywPrice: 100,
  setPwywPrice: F,
  priceOffer: 'averagePrice',
  setPriceOffer: F
})

export const usePwywContext = (): PwywContextType => useContext(PwywContext)
