import { Product, ResponsiveImage } from 'src/generated/graphql'
import { ProductType } from '@concepts/Sales/types/sale'
import { Media } from '@molecules/SaleGallery/SaleGallery'

export const parse = (product: Product): ProductType => ({
  ...product,
  iconImage: product.iconImage || ({ url: '' } as ResponsiveImage),
  medias: [...parseImages(product), ...parseVideos(product)]
})

const parseVideos = (product: Product): Media[] | [] =>
  product.videos?.map((url: string) => ({
    url,
    thumb: '',
    playing: false
  })) || []

const parseImages = (product: Product): Media[] | [] => {
  const thumbs =
    product.imageCollection?.thumbnails.map(
      (item) => item.pictureAttributes.source_tags[0].srcset
    ) || []

  const images = (product.imageCollection?.productShots || []).map(
    (item, index) => ({
      url: item.url as string,
      thumb: thumbs.length > index ? (thumbs[index] as string) : ''
    })
  )

  return images
}

export default { parse }
