import RouterLink from 'next/link'
import { is, pick, values } from 'ramda'
import { Link as UILink, LinkProps as LinkRebassProps } from 'rebass'
import { isURL } from '@utils/url'

export type PathObject = {
  pathname?: string
  hash?: string
  search?: string
}

export type Path = string | PathObject

export type LinkProps = {
  to: Path
  external?: boolean
  prefetch?: boolean
  shallow?: boolean
} & LinkRebassProps & { css?: string }

const mountURL = (to: Path): string =>
  is(String, to)
    ? (to as string)
    : values(pick(['pathname', 'hash', 'search'], to)).join('')

const Link = (props: LinkProps) => {
  const { external = true, to, prefetch, target, shallow, ...UIProps } = props
  const isExternal = external || isURL(to.toString())

  if (isExternal) {
    const rel = target === '_blank' ? 'noreferrer noopener' : undefined
    return <UILink href={mountURL(to)} target={target} rel={rel} {...UIProps} />
  } else {
    return (
      <RouterLink
        href={to}
        prefetch={prefetch}
        shallow={Boolean(shallow)}
        passHref
      >
        <UILink href={mountURL(to)} target={target} {...UIProps} />
      </RouterLink>
    )
  }
}

export default Link
