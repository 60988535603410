import { isEmpty } from 'ramda'

import ListContainer from '@atoms/ListContainer/ListContainer'

import { Box, Text } from '@lib/UIComponents'

export type TextListItem = {
  title?: string
  body?: string
}

type TextListProps = {
  list: TextListItem[]
}

const TextList = ({ list }: TextListProps) => {
  if (isEmpty(list)) return null

  return (
    <ListContainer>
      {list.map((item) => (
        <Box
          as="li"
          key={item.title}
          sx={{
            '&:not(:last-of-type)': {
              mb: [4, 8]
            }
          }}
        >
          <Text sx={{ fontWeight: 'bold' }}>{item.title}</Text>
          <Text as="p">{item.body}</Text>
        </Box>
      ))}
    </ListContainer>
  )
}

export default TextList
