/* istanbul ignore file */
import { isNil } from 'ramda'

import { pluralize } from '@utils/strings'
import { StarIcon } from '@atoms/Icon/Icon'
import { getStarsWidth, RATING_STARS_RANGE } from './utils'
import { Box, Flex, Text } from '@lib/UIComponents'

type Props = {
  average?: number
  total?: number
  small?: boolean
}

const styles = (small: boolean) => ({
  wrapper: {
    display: small ? 'flex' : 'block',
    alignItems: 'center',
    justifyContent: ['center', 'center', 'normal'],
    columnGap: [3]
  },
  reviews: {
    fontSize: [1, 1],
    color: 'text',
    margin: 0,
    letterSpacing: small ? 'body' : '0.135em',
    fontWeight: small ? 'body' : 'bold',
    lineHeight: 1,
    order: small ? '1' : '2'
  },
  rate: {
    fontSize: small ? 'sm' : [5, 6],
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: 'text',
    ml: 8,
    small: {
      display: small ? 'none' : 'span',
      fontSize: 'sm',
      lineHeight: 0,
      fontWeight: 'normal',
      color: 'grays.600',
      ml: 2,
      position: 'relative',
      top: '3px'
    }
  },
  starContainer: {
    mt: 1
  }
})

const RatingsAverage: React.FC<React.PropsWithChildren<Props>> = ({
  average = 0,
  total,
  small = false
}) => {
  if (!average && !total) return null

  const variantStyles = styles(small)

  return (
    <Box sx={variantStyles.wrapper}>
      {total && (
        <Text as="strong" casing="uppercase" sx={variantStyles.reviews}>
          {total} {pluralize(total, 'Review', 'Reviews')}
        </Text>
      )}
      <Flex sx={variantStyles.starContainer}>
        {RATING_STARS_RANGE.map((value) => {
          const shouldApplyPercentageStar = average < value

          return (
            <Box
              key={value}
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                color: value <= average ? 'primary' : 'grays.100',
                svg: {
                  width: small ? ['17px'] : ['26px', '30px'],
                  height: small ? ['17px'] : ['26px', '30px']
                }
              }}
            >
              <StarIcon />

              {shouldApplyPercentageStar && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '1px',
                    left: 0,
                    width: getStarsWidth(value, average),
                    overflow: 'hidden',
                    svg: {
                      color: 'primary'
                    }
                  }}
                >
                  <StarIcon />
                </Box>
              )}
            </Box>
          )
        })}
        {!isNil(average) && (
          <>
            <Text sx={variantStyles.rate} as="span">
              {average}
              {!small && <small>/ 5</small>}
            </Text>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default RatingsAverage
