import { drop } from 'ramda'

import Avatar from '@atoms/Avatar/Avatar'
import { UserStarIcon } from '@atoms/Icon/Icon'
import Currency from '@atoms/UICurrency/Currency'
import Heading from '@atoms/UIHeading/Heading'
import { LeaderboardChampionProps as ArrayContentProps } from '@molecules/LeaderboardChampion/LeaderboardChampion'
import { Box, Flex, Text } from '@lib/UIComponents'

interface Props {
  users: Array<ArrayContentProps>
  startWithSecond?: boolean
}

const styles = {
  container: {
    backgroundColor: 'background',
    maxWidth: '874px',
    my: 0,
    mx: 'auto',
    border: '1px solid',
    borderColor: 'grays.100'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    justifyContent: 'space-around',
    alignItems: 'center',
    gridGap: [4, '8%']
  },
  table: {
    top: {
      pt: [3, 5],
      pb: [3, 6],
      borderBottom: '1px solid',
      borderColor: 'grays.100'
    },
    heading: {
      font: 'texts.heading',
      fontWeight: 'body',
      fontSize: [1, 2],
      lineHeight: '150%',
      color: 'blacks.500'
    },
    row: {
      my: 4,
      mx: 0
    },
    rowContent: {
      justifySelf: 'center',
      textAlign: 'center'
    },
    content: {
      pl: 4,
      pr: 5
    }
  },
  icon: {
    base: {
      display: 'inline-block',
      lineHeight: 'button',
      pt: 1,
      fontSize: 4
    },
    grid: {
      display: 'grid',
      gridGap: [1, 3],
      gridTemplateColumns: '18px 1fr'
    },
    space: {
      display: 'inline-block'
    }
  }
}

const colorIcon = (index: number) =>
  [
    {
      color: 'highlight'
    },
    {
      color: 'grays.250'
    },
    {
      color: 'danger'
    }
  ][index - 1]

const LeaderboardTable: React.FC<React.PropsWithChildren<Props>> = ({
  users,
  startWithSecond = false
}) => {
  if (!users.length) {
    return null
  }

  const list = startWithSecond ? drop(1, users) : users
  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.grid, ...styles.table.top }}>
        <Box sx={styles.table.rowContent}>
          <Heading as="span" level={5}>
            Rank
          </Heading>
        </Box>
        <Box>
          <Heading as="span" level={5}>
            User
          </Heading>
        </Box>
        <Box sx={styles.table.rowContent}>
          <Heading as="span" sx={{ ml: -7 }} level={5}>
            Paid
          </Heading>
        </Box>
      </Box>
      <Box sx={styles.table.content}>
        {list.map((user, key) => (
          <Box sx={{ ...styles.grid, ...styles.table.row }} key={key}>
            <Box sx={styles.table.rowContent}>
              <Box>
                <Text
                  sx={{
                    ...styles.icon.grid,
                    ...styles.table.heading,
                    mr: ['27px']
                  }}
                >
                  {user.rank <= 3 ? (
                    <Box
                      as="span"
                      sx={{ ...styles.icon.base, ...colorIcon(user.rank) }}
                    >
                      <UserStarIcon data-testid={`starIcon${key}`} />
                    </Box>
                  ) : (
                    <Box as="span" sx={styles.icon.space} />
                  )}
                  <Box as="span" sx={{ pt: '1px', ml: '7px' }}>
                    {user.rank}
                  </Box>
                </Text>
              </Box>
            </Box>
            <Flex sx={{ alignItems: 'center' }}>
              <Avatar
                src={user.avatarSrc}
                alt={user.name}
                firstName={user.name}
                size="small"
                fontSize={['12px', '12px']}
              />
              <Text
                sx={{
                  ...styles.table.heading,
                  pl: [2, 4],
                  pb: 3,
                  mt: [3, 2]
                }}
              >
                {user.name}
              </Text>
            </Flex>
            <Box sx={styles.table.rowContent}>
              <Currency sx={styles.table.heading}>
                {user.paidPriceCents}
              </Currency>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default LeaderboardTable
