import Avatar from '@atoms/Avatar/Avatar'
import { VerifiedIcon } from '@atoms/Icon/Icon'

import Link from '@atoms/UILink/Link'
import { Box, Flex, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { detectLanguage } from '@lib/lang'

import RatingStars from '@molecules/Ratings/RatingStars'
import ReviewResponse from '@molecules/ReviewResponse/ReviewResponse'

import { useAuthContext } from '@concepts/Auth/store/context'
import { formatDateShortMonthDDYYYY } from '@utils/dates'
import { isPresent } from '@utils/logic'
import { saleReview } from '@config/routes'

export type Review = {
  databaseId: number
  comment: string
  rating: number
  edited: boolean
  createdAt?: string
  updatedAt?: string
  user: {
    databaseId?: number
    avatarUrl?: string
    firstName?: string
    lastName?: string
  }
  saleRatingResponse?: {
    comment: string
    updatedAt: string
  }
  sale: {
    slug: string
  }
}

type Props = {
  reviewData: Review
  sx?: UIStyles
}

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: [
      '100%',
      '100%',
      '330px auto',
      '330px auto',
      '330px auto'
    ],
    pt: 6,
    mt: 5,
    borderTop: '1px solid',
    borderColor: 'grays.100'
  },
  title: {
    fontSize: 'md',
    lineHeight: '1.5',
    fontWeight: 'bold',
    color: 'blacks.600',
    mb: 1
  },
  profile: {
    fontFamily: 'body',
    display: 'flex',
    alignItems: 'flex-start'
  },
  profileWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 'md',
    lineHeight: '1.5',
    fontWeight: 'body',
    color: 'blacks.500'
  },
  avatar: {
    minWidth: ['48px', '60px'],
    minHeight: ['48px', '60px'],
    borderRadius: 'circle',
    bg: 'info',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: 'primaries.8',
    fontSize: ['23px', '23px'],
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  text: {
    ml: 1
  },
  data: {
    display: 'block',
    ml: 4
  },
  commentBox: {
    display: 'block',
    mt: 2
  },
  comment: {
    display: 'block',
    mt: 1,
    wordBreak: 'break-word'
  },
  date: {
    display: 'block',
    color: 'grays.600',
    mt: 3
  },
  verified: {
    position: 'relative',
    top: '1px'
  }
}

const ReviewItem = ({ reviewData, sx = {} }: Props) => {
  const [{ user }] = useAuthContext()
  const fullName = `${reviewData.user.firstName || ''} ${
    reviewData.user.lastName || ''
  }`

  return (
    <>
      <Box sx={{ ...styles.wrapper, ...sx }} data-testid="reviewItem">
        <Box sx={styles.profile}>
          <Box sx={styles.profileWrapper}>
            <Avatar
              alt={`${fullName} profile picture`}
              src={reviewData.user.avatarUrl || ''}
              size="medium"
              firstName={reviewData.user.firstName as string}
              lastName={reviewData.user.lastName}
            />
            <Box sx={styles.data}>
              {isPresent(fullName.trim()) && (
                <Text
                  as="h3"
                  sx={styles.title}
                  lang={detectLanguage(fullName)}
                  dir="auto"
                >
                  {fullName}
                </Text>
              )}

              <Box sx={styles.textBox}>
                <Flex sx={{ mr: 1, alignItems: 'center' }}>
                  <VerifiedIcon />
                </Flex>
                <Text sx={styles.text}>Verified Buyer</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.commentBox}>
          <Flex>
            {reviewData.rating && (
              <RatingStars starNumber={reviewData.rating} />
            )}
            {reviewData.edited && (
              <Text sx={{ color: 'grays.600', ml: 6 }}>Edited</Text>
            )}
          </Flex>
          <Text
            as="p"
            sx={styles.comment}
            lang={detectLanguage(reviewData.comment)}
            dir="auto"
          >
            {reviewData.comment}
          </Text>
          <Flex sx={{ mt: 3 }}>
            <Text as="span" sx={styles.date}>
              {formatDateShortMonthDDYYYY(reviewData.createdAt as string)}
            </Text>
            {user && user.id === reviewData.user.databaseId && (
              <Link
                sx={{
                  fontSize: ['sm', 'md'],
                  ml: 8,
                  color: 'primary',
                  textDecoration: 'underline'
                }}
                to={saleReview(reviewData.sale.slug)}
              >
                Edit review
              </Link>
            )}
          </Flex>
        </Box>
      </Box>

      {reviewData.saleRatingResponse && (
        <ReviewResponse
          comment={reviewData.saleRatingResponse.comment}
          date={formatDateShortMonthDDYYYY(
            reviewData.saleRatingResponse.updatedAt
          )}
        />
      )}
    </>
  )
}

export default ReviewItem
