import Card from '@atoms/UICard/Card'
import Heading from '@atoms/UIHeading/Heading'
import { CheckMarkIcon } from '@atoms/Icon/Icon'
import { Flex, Icon, Text } from '@lib/UIComponents'

export type TLDRItemProps = {
  header: string
  description: string
}

const TLDRItem = ({ header, description }: TLDRItemProps) => {
  return (
    <Card
      sx={{
        py: [7, 12],
        px: 5,
        backgroundColor: 'white'
      }}
    >
      <Flex
        sx={{
          color: 'primary',
          fontSize: 'xl',
          mb: 2,
          alignItems: 'center'
        }}
      >
        <Icon as={CheckMarkIcon} sx={{ mr: 2 }} />
        <Heading sx={{ fontSize: [2, 3], fontWeight: 'bold' }}>
          {header}
        </Heading>
      </Flex>
      <Text as="p" sx={{ fontSize: ['md', 'lg'], fontWeight: 'bold' }}>
        {description}
      </Text>
    </Card>
  )
}

export default TLDRItem
