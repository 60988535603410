import { Box } from 'rebass'

import Container from '@atoms/Container/Container'
import SingleLineSeparator from '@atoms/Separator/SingleLineSeparator'
import { SaleDetailsProps } from '@concepts/Sales/types/sale'
import CardGrid from '@molecules/CardGrid/CardGrid'
import CollapsibleCardList from '@molecules/CollapsibleCardList/CollapsibleCardList'
import SalePageHeader from '@organisms/SalePageHeader/SalePageHeader'
import LeaderboardRank from 'src/components/organisms/LeaderboardRank/LeaderboardRank'
import { usePwywContext } from './store/pwywContext'
import { getLockedProducts } from './utils'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import { isPresent } from '@utils/logic'
import SaleHeader from '@molecules/SaleHeader/SaleHeader'

const styles = {
  container: {
    pt: [6, 6, 9, 10],
    pb: [6, 6, 9]
  }
}

const Nyop: React.FC<React.PropsWithChildren<SaleDetailsProps>> = ({
  sale
}) => {
  const { pwywPrice, priceOffer } = usePwywContext()
  const {
    priceToBeatAverageCents,
    products,
    retailPriceInCents,
    miniBundle,
    specialSaleBannerBgUrl
  } = sale

  const lockedGrid =
    priceOffer !== 'averagePrice' && pwywPrice < priceToBeatAverageCents

  const totalValue = Number((retailPriceInCents / 100).toFixed(0)) * 100
  const reversedActiveProducts = getLockedProducts(
    products,
    miniBundle,
    lockedGrid
  )

  const FeatureFlag = useFeatureFlag()
  const shouldDisplaySaleBannerImg =
    FeatureFlag.isEnabled('ssr_use_sale_banner', `sale:${sale.id}`) &&
    isPresent(sale.specialSaleBannerBgUrl)

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: shouldDisplaySaleBannerImg
            ? [null, null, null, `url(${specialSaleBannerBgUrl}) `]
            : null,
          backgroundSize: 'cover',
          backgroundPosition: ['center', 'center']
        }}
      >
        <SaleHeader
          sale={sale}
          sx={{
            maxWidth: ['100%', '100%', '100%', '80%'],
            display: ['block', 'block', 'block', 'none']
          }}
          placedOnImageBackground={shouldDisplaySaleBannerImg}
        />

        <Container sx={styles.container} withDefaultPadding>
          <SalePageHeader
            sale={sale}
            placedOnImageBackground={shouldDisplaySaleBannerImg}
          />
        </Container>
      </Box>

      <Container sx={styles.container} withDefaultPadding>
        <CardGrid totalValue={totalValue} products={reversedActiveProducts} />
      </Container>

      <LeaderboardRank users={sale.leaderboard} />

      <Container
        sx={{
          pt: [0, 0, 0],
          pb: [7, 7, 7]
        }}
        withDefaultPadding
      >
        <CollapsibleCardList
          products={sale.products}
          terms={sale.terms}
          countries={sale.shippingMessage}
          title="Product Details"
          id="product-details"
        />
      </Container>

      <SingleLineSeparator />
    </Box>
  )
}

export default Nyop
