import { useState } from 'react'
import { Notyf } from 'notyf'

import { useApollo } from '@lib/apollo'
import { SaleDetailsType } from '../types/sale'
import PrepareCartForCheckout, {
  PrepareCartForCheckoutResult
} from '@concepts/Cart/app/PrepareCartForCheckout'
import { useNotyfContext } from '@atoms/Notyf/context'
import { CheckoutGateway } from 'src/generated/graphql'
import errorFormatter from '@concepts/Checkout/utils/errorFormatter'
import useOrderSuccessful from '@concepts/Checkout/hooks/useOrderSuccessful'
import ProcessCheckoutRepository from '@concepts/Payment/repository/ProcessCheckoutRepository'

import { useReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'
import { RecaptchaAction } from '@concepts/ReCaptcha/types/actions'
import { useCartContext } from '@concepts/Cart/store/context'

export type UseFreebieType = {
  sale: SaleDetailsType
}

type UseFreebieReturnType = {
  prepareFreebieForCheckout: Function
  freebieProcessCheckout: Function
  isButtonDisabled: boolean
  setIsButtonDisabled: Function
  loading: boolean
}

const useFreebie = ({ sale }: UseFreebieType): UseFreebieReturnType => {
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const { currentToken } = useReCaptchaContext()
  const apolloClient = useApollo()
  const notyf = useNotyfContext()
  const { orderSuccessful } = useOrderSuccessful()
  const { cart } = useCartContext()

  const prepareFreebieForCheckout = () => {
    setLoading(true)

    PrepareCartForCheckout.apply(
      {
        gateway: CheckoutGateway.Stripe,
        attributes: {
          cartForSinglePayment: true,
          forceCartClearing: true,
          item: {
            saleId: sale.id,
            quantity: 1
          }
        }
      },
      apolloClient
    )
      .then((data) => {
        freebieProcessCheckout(data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const freebieProcessCheckout = async (
    params: PrepareCartForCheckoutResult
  ) => {
    const captchaToken = await currentToken(
      RecaptchaAction.RECAPTCHA_CHECKOUT
    ).catch((e: Error) => e.message)

    if (captchaToken instanceof Error || !captchaToken) {
      setLoading(false)
      ;(notyf as Notyf).error({
        message: 'Captcha failed. Please try again later',
        duration: 0
      })

      return
    }

    const attrs = {
      cartId: params.cart.id,
      gateway: CheckoutGateway.Stripe,
      recaptchaParameters: {
        token: captchaToken['g-recaptcha-response'],
        forAction: captchaToken.for_action,
        resolver: captchaToken.resolver
      }
    }

    const response = await ProcessCheckoutRepository(attrs, apolloClient)

    if (response.success) {
      orderSuccessful({
        order: response.order,
        gtoken: response.gtoken,
        uidocp: response.uidocp,
        gateway: CheckoutGateway.Stripe,
        cart
      })
    } else {
      setLoading(false)
      ;(notyf as Notyf).error({
        message: errorFormatter(response.errors),
        duration: 0
      })
    }
  }

  return {
    prepareFreebieForCheckout,
    freebieProcessCheckout,
    isButtonDisabled,
    setIsButtonDisabled,
    loading
  }
}

export default useFreebie
