import { useState } from 'react'
import { useSpring } from 'react-spring'
import useMediaQuery from '@hooks/useMediaQuery'
import useElementSize from '@hooks/useElementSize'

type UseShowMore = {
  maxHeight: number
  mobileOnly: boolean
  scrollOnClose: boolean
}

const useShowMore = ({ maxHeight, mobileOnly, scrollOnClose }: UseShowMore) => {
  const [isOpen, setIsOpen] = useState(false)

  const isMobile = useMediaQuery()
  const [ref, setRef, size] = useElementSize()

  const contentMaxHeight = size.height

  const defaultHeight = isMobile
    ? `${maxHeight}px`
    : `${mobileOnly ? contentMaxHeight : maxHeight}px`

  const { opacity, ...props } = useSpring({
    maxHeight: isOpen ? `${contentMaxHeight}px` : defaultHeight,
    config: { mass: 1, tension: 370, friction: 60 },
    opacity: isOpen ? 0 : 1
  })

  const toggleButton = () => {
    const newIsOpen = !isOpen

    setIsOpen(newIsOpen)

    const isClosed = !newIsOpen

    if (isClosed && scrollOnClose) {
      const showMoreBox = ref as HTMLDivElement

      showMoreBox.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return {
    setAnimationRef: setRef,
    toggleButton,
    opacity,
    props,
    isOpen
  }
}

export { useShowMore }
