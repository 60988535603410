import Radio from '@atoms/Radio/Radio'
import Link from '@atoms/Link/Link'
import Text from '@atoms/Text/Text'
import { useAddToCartContext } from '@concepts/Sales/store/addToCartContext'
import OptionRadioDiscount, {
  Props as RadioDiscountProps,
  fontBase
} from './OptionRadioDiscount'
import OptionRadioPWYW from './OptionRadioPWYW'

const styles = {
  fontBase,
  wrapper: {
    label: {
      ...fontBase,
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    display: 'grid',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'center',
    cursor: 'pointer'
  },
  link: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content'
  },
  soldOut: {
    opacity: 0.5,
    bg: 'grays.0',
    pointerEvents: 'none',
    userSelect: 'none',
    color: 'black',
    '.prices': {
      display: 'none'
    }
  }
}

export enum RadioType {
  DISCOUNT = 'discount',
  PWYW = 'pwyw'
}

type OptionProps = {
  id: string
  name?: string
  slug: string
  soldOut?: boolean
}

export type Props = {
  name?: string
  optionData: OptionProps & RadioDiscountProps
  checked?: boolean
  sx?: object
  radioType?: RadioType
  soldOut?: boolean
}

const OptionRadio: React.FC<React.PropsWithChildren<Props>> = ({
  name = '',
  optionData,
  radioType = RadioType.DISCOUNT,
  sx = {},
  checked,
  soldOut,
  ...props
}) => {
  const { setIsSubmitting } = useAddToCartContext()

  const { id, slug, priceInCents, retailPriceInCents, calculatedDiscount } =
    optionData

  return (
    <Link
      onClick={() => {
        setIsSubmitting(true)
      }}
      to={slug}
      sx={{
        ...sx,
        ...styles.wrapper,
        ...(soldOut && styles.soldOut)
      }}
      data-testid={`option-radio-${id}`}
    >
      <Radio
        id={id}
        label={name}
        checked={checked}
        disabled
        sxContainer={{
          minHeight: ['40px', '40px', '20px'],
          alignItems: 'center',
          display: 'flex'
        }}
        sxLabel={{ pointerEvents: 'none' }}
        {...props}
      />
      {
        {
          discount: (
            <OptionRadioDiscount
              priceInCents={priceInCents}
              retailPriceInCents={retailPriceInCents}
              calculatedDiscount={calculatedDiscount}
            />
          ),
          pwyw: <OptionRadioPWYW />
        }[radioType]
      }

      {soldOut && <Text> SOLD OUT </Text>}
    </Link>
  )
}

export default OptionRadio
